import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ALL_VALUE } from 'containers/App/app.constants';
import { SORT_TYPES_MAP } from 'components/DataGrid/dataGrid.constants';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';
import { parseFetchAllRequest as parseAllRequest } from 'containers/App/modules/entities/users/users.parsers';

export const parseFetchAllRequest = payload =>
	parseAllRequest(payload);

export const parseFetchAllSuccess = responseData => {
	return responseData.data;
};
	

