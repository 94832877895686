import _ from 'lodash';
import { parseFetchAllRequest as parseAllRequest } from 'containers/App/modules/entities/users/users.parsers';

export const parseFetchAllRequest = payload =>
	parseAllRequest(payload);

export const parseFetchAllSuccess = responseData => {
    return responseData.data;
};

