import { connect } from 'react-redux';
import { compose } from 'redux';

import DeviceForm from '../components/DeviceForm';
import { deviceEntityActions, deviceEntitySelectors } from 'containers/App/modules/entities/devices/deviceEntity.slice';
import { devicesActions } from 'pages/Devices/modules/devices.slice';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';
import _ from 'lodash';


const mapStateToProps = (state, props) => {
	const {device }= props;
	let editBlocked;
	if (device) {
		const isActiveDevice = _.get(device, 'status');
		const uiCanEditCustomer = hasPermission(PERMISSIONS.UI_EDIT_CUSTOMER_FOR_DEVICE);
		const uiCanEditCustomerForActiveDevice = hasPermission(PERMISSIONS.UI_EDIT_CUSTOMER_FOR_ACTIVE_DEVICE);
		//edit customer details if user has UI_EDIT_CUSTOMER_FOR_DEVICE and device is inactive, or device is active and user has UI_EDIT_CUSTOMER_FOR_ACTIVE_DEVICE
		const canEditCustomerDetails = !uiCanEditCustomer ? false :!isActiveDevice ? true : uiCanEditCustomerForActiveDevice;
		editBlocked = !canEditCustomerDetails;
	}
	return {
		isCreateUpdateLoading: deviceEntitySelectors.selectCreateDevicePending(state),
		editBlocked
	};
};

const withConnect = connect(mapStateToProps, {...deviceEntityActions, ...devicesActions, ...modalActions});

export default compose(
	withConnect
)(DeviceForm);