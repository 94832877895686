import React from 'react';
import _ from 'lodash';
import { Input } from 'antd';


const Search = React.forwardRef((props, ref) => {

	const {
		      onChange,
		      debounceInterval = 300,
		      ...inputProps
	      } = props;

	let debouncedTypingFn;
	const onChangeDebounced = (e) => {
		//React SyntheticEvent problem when using debounce
		e.persist();

		if (!debouncedTypingFn) {
			debouncedTypingFn = _.debounce(() => {
				console.log(`debounced`, e.target.value);
				onChange && onChange(e.target.value);
			}, debounceInterval);
		}
		debouncedTypingFn();
	};

	return (
		<Input
			ref={ref}
			allowClear
			placeholder="Search"
			{...inputProps}
			onChange={onChangeDebounced}
		/>
	);
});

Search.propTypes = Input.propTypes;

export default Search;