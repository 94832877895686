/* eslint import/no-mutable-exports: 0 */
/* eslint prefer-const: 0 */

import ProdConfig from './ProdConfig';
import DevConfig from './DevConfig';


const appConfig = window.BIOT_MED_APP_CONFIG || {};

let APP_CONFIG =
	    appConfig ? appConfig : process.env.NODE_ENV === 'development' ?
            DevConfig : ProdConfig;

export default APP_CONFIG;

export const mergeAppConfig = newConf => {
	APP_CONFIG = {
		...APP_CONFIG,
		newConf,
	};
};
