import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { ACTION_STATUS } from 'containers/App/app.constants';
import { ENTITIES_STATE_KEY, ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';


export const STATE_KEY = ENTITY_TYPES.ALARMS;

export const INITIAL_STATE = {
  alarms: {},
  getAlarmStatus: {},
};

const slice = createSlice({
	name:          STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		getAlarms: (state) => {
      state.alarms = {};
      state.getAlarmStatus = ACTION_STATUS.RUNNING;
      return state;
    },
    getAlarmsSuccess: (state, action) => {
      state.alarms = action.payload;
      state.getAlarmStatus = ACTION_STATUS.SUCCESS;
      return state;
    },
    getAlarmsFailed: (state) => {
      state.alarms = {};
      state.getAlarmStatus = ACTION_STATUS.ERROR;
      return state;
    },
	}
});

// const getState = state => state[STATE_KEY] || INITIAL_STATE;
const getEntitiesState = state => state[ENTITIES_STATE_KEY] || {};

const getState = createSelector(
	getEntitiesState,
	state => _.get(state, STATE_KEY)
);

const selectAlarms = createSelector(
	getState,
	state => _.get(state, 'alarms')
);


const selectAlarmsStatus = createSelector(
	getState,
	state => _.get(state, 'getAlarmStatus')
);

export const selectors = {
  selectAlarms,
  selectAlarmsStatus,
};

const {
  getAlarms,
  getAlarmsFailed,
  getAlarmsSuccess,
} = slice.actions;
export const actions = {
  getAlarms,
  getAlarmsFailed,
  getAlarmsSuccess,
};
export default slice.reducer;