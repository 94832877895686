import React from 'react';
import EntityGridToolbar from 'containers/Grid/components/EntityGridToolbar';
import _ from 'lodash';
import { MODAL_TYPES } from 'containers/ModalRoot/components/constants';
import { FormattedMessage } from 'react-intl';
import { PERMISSIONS } from 'services/PermissionsService';

import FormField from 'components/FormFactory/FormField';

import messages from 'pages/Devices/components/messages';
import DeviceTypeTreeSelector from 'containers/DeviceTypeTreeSelector';
import LocationTreeSelector from 'containers/LocationTreeSelector';

const TREE_SELECTOR_DEFAULT_MAX_TAG_NUMBER = 3;

const DevicesGridToolbar = (props) => {
	const {
		      setGridParams,
		      grid,
		      showAddNew = true,
			  showModal,
			  treeSelectorMaxTagCount
	      } = props;

	const onFiltersChange = (filterValues) => {
		const { deviceTypeFilter, countryFilter } = filterValues;
		const filters = {};
		if (!_.isEmpty(deviceTypeFilter)) {
			filters['deviceInfo.deviceType'] = deviceTypeFilter;
		}
		if (!_.isEmpty(countryFilter)) {
			filters['customerDetails.address.location'] = countryFilter;
		}

		setGridParams({
			...grid.params,
			pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
			filters
		});
	};

	const onSearchChange = (value) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
			search:     value
		});
	};

	const onAddNew = () => {
		showModal({
			modalType:  MODAL_TYPES.CREATE_DEVICE_MODAL,
			modalProps: {
				title:  <FormattedMessage {...messages.createDevice} />,
				footer: null,
				width:  1000,
			}
		});
	};

	const onResetFilters = (form) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1, pageSize: 10 }, //return to page 1 on new search
			search:     '',
			filters:    {}
		});
	};

	const filterFormElements = (
		<>

			<FormField
				name="deviceTypeFilter"
				style={{ minWidth: 350 }}>
				<DeviceTypeTreeSelector allowClear={true} maxTagCount={treeSelectorMaxTagCount || TREE_SELECTOR_DEFAULT_MAX_TAG_NUMBER} />
			</FormField>

			<FormField
				name="countryFilter"
				style={{ minWidth: 200 }}>
				<LocationTreeSelector allowClear={true} maxTagCount={treeSelectorMaxTagCount || TREE_SELECTOR_DEFAULT_MAX_TAG_NUMBER}/>
			</FormField>
		</>
	);

	return (
		<EntityGridToolbar
			onResetFilters={onResetFilters}
			onAddNew={onAddNew}
			onFiltersChange={onFiltersChange}
			onSearchChange={onSearchChange}
			filterFormElements={filterFormElements}
			showAddNew={showAddNew}
			addNewPermission={PERMISSIONS.UI_CREATE_DEVICE}
		/>
	);
};

DevicesGridToolbar.propTypes = {};

export default DevicesGridToolbar;