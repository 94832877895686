import { call, put, takeLatest } from "redux-saga/effects";
import BackendService, { publishedVersions } from "services/BackendService";
import { groupEntityActions } from "containers/App/modules/entities/groups/groupEntity.slice";
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { parseFetchAllSuccess, parseFetchAssignedDevicesSuccess } from "containers/App/modules/entities/groups/group.parsers";
import { createEntityFailure, createEntitySuccess } from "containers/App/modules/entities/entities.utils";
import { showErrorToast, showSuccessToast, } from "containers/App/modules/toasts/toasts.actions";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";

function* fetchGroups(action) {
	yield call(api, {
		apiFn: BackendService.fetchGroups,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.group],
		params: action.payload,
		actions: [
			groupEntityActions.fetchGroupsPending,
			groupEntityActions.fetchGroupsSuccess,
			groupEntityActions.fetchGroupsFailure,
		],
	});
}

function* createGroup(action) {
	const params = action.payload;

	yield call(api, {
		apiFn: BackendService.createGroup,
		params,
		actions: [
			groupEntityActions.createGroupPending,
			groupEntityActions.createGroupSuccess,
			groupEntityActions.createGroupFailure,
		],
	});
}

/*
function* fetchGroup(action) {
	const groupId = action.payload;
	yield call(api, {
		apiFn:                  BackendService.fetchGroup,
		schema:                 schemas.group,
		params:                 groupId,
		actions:                [
			groupEntityActions.fetchGroupPending,
			groupEntityActions.fetchGroupSuccess,
			groupEntityActions.fetchGroupFailure
		]
	});
}

function* updateGroup(action) {
	yield call(api, {
		apiFn:   BackendService.updateGroup,
		params:  action.payload,
		actions: [
			groupEntityActions.updateGroupPending,
			groupEntityActions.updateGroupSuccess,
			groupEntityActions.updateGroupFailure
		]
	});
}
*/

function* fetchAssignedDevices(action) {
	yield call(api, {
		apiFn: BackendService.fetchAssignedDevices,
		parseSuccessResponseFn: parseFetchAssignedDevicesSuccess,
		params: action.payload,
		actions: [
			groupEntityActions.fetchAssignedDevicesPending,
			groupEntityActions.fetchAssignedDevicesSuccess,
			groupEntityActions.fetchAssignedDevicesFailure,
		],
	});
}

function* updateAssignedDevices(action) {
	yield call(api, {
		apiFn: BackendService.updateAssignedDevices,
		params: action.payload,
		actions: [
			groupEntityActions.updateAssignedDevicesPending,
			groupEntityActions.updateAssignedDevicesSuccess,
			groupEntityActions.updateAssignedDevicesFailure,
		],
	});
}

function* updateAssignedDevicesSuccess(action) {
	yield put(modalActions.hideModal());
	yield put(
		showSuccessToast({
			title: "Assigned device(s) to group successfully",
		})
	);
}

function* updateAssignedDevicesFailure(action) {
	yield put(
		showErrorToast({
			title: "Assign device(s) to group failed",
		})
	);
}

function* updateAssignedDevicesWithAllMode(action) {
	yield call(api, {
		apiFn: BackendService.updateAssignedDevicesWithAllMode,
		params: action.payload,
		actions: [
			groupEntityActions.updateAssignedDevicesWithAllModePending,
			groupEntityActions.updateAssignedDevicesWithAllModeSuccess,
			groupEntityActions.updateAssignedDevicesWithAllModeFailure,
		]})

	}
function* fetchVersions(action) {
	yield call(api, {
		apiFn:   BackendService.fetchGroupVersions,
		params:  action.payload,
		actions: [
			groupEntityActions.fetchVersionsPending,
			groupEntityActions.fetchVersionsSuccess,
			groupEntityActions.fetchVersionsFailure
		]
	});
}

function* publishVersionToGroup(action) {
	const { payload } = action;
	publishedVersions[payload.groupName].swVersion = payload.softwareVersion || publishedVersions[payload.groupName].swVersion;
	publishedVersions[payload.groupName].cloudSdkVersion = payload.lumenisXVersion || publishedVersions[payload.groupName].cloudSdkVersion;

	yield call(api, {
		apiFn:   BackendService.publishVersionToGroup,
		params:  action.payload,
		actions: [
			groupEntityActions.publishVersionToGroupPending,
			groupEntityActions.publishVersionToGroupSuccess,
			groupEntityActions.publishVersionToGroupFailure
		]
	});

	yield call(fetchGroups, action);
}

function* publishVersionToGroupSuccess(action) {
  yield put(modalActions.hideModal());
	yield put(showSuccessToast({
    title: 'Version published to group successfully',
  }))
}

function* publishVersionToGroupFailure(action) {
	yield put(showErrorToast({
    title: 'Version publish to group failed',
  }))
}

function* fetchGroupDevices(action) {
	yield call(api, {
		apiFn:   BackendService.fetchGroupDevices,
		params:  action.payload,
		actions: [
			groupEntityActions.fetchGroupDevicesPending,
			groupEntityActions.fetchGroupDevicesSuccess,
			groupEntityActions.fetchGroupDevicesFailure
		]
	});
}

function* updateGroup(action) {
	yield call(api, {
		apiFn:   BackendService.updateGroup,
		params:  action.payload,
		actions: [
			groupEntityActions.updateGroupPending,
			groupEntityActions.updateGroupSuccess,
			groupEntityActions.updateGroupFailure
		]
	});
}

function* onUpdateGroupSuccess(action) {
	yield put(modalActions.hideModal());
	yield put(showSuccessToast({
    title: 'Updated group successfully',
  }))
}

function* updateEntityFailure(action) {
	yield put(modalActions.hideModal());
	yield put(showErrorToast({
    title: 'Update group failed',
  }))
}

export default function* watchGroupsSaga() {
	yield takeLatest(groupEntityActions.fetchGroups, fetchGroups);
	// yield takeLatest(groupEntityActions.fetchGroup, fetchGroup);

	yield takeLatest(groupEntityActions.createGroup, createGroup);
	yield takeLatest(groupEntityActions.createGroupSuccess, createEntitySuccess);
	yield takeLatest(groupEntityActions.createGroupFailure, createEntityFailure);

	yield takeLatest(groupEntityActions.updateGroup, updateGroup);
	yield takeLatest(groupEntityActions.updateGroupSuccess, onUpdateGroupSuccess);
	yield takeLatest(groupEntityActions.updateGroupFailure, updateEntityFailure);

	// yield takeLatest(groupEntityActions.updatePreferences, updatePreferences);
	// yield takeLatest(groupEntityActions.updatePreferencesSuccess, onUpdatePreferencesSuccess);
	// yield takeLatest(groupEntityActions.updatePreferencesFailure, onUpdatePreferencesFailure);

	yield takeLatest(groupEntityActions.fetchAssignedDevices, fetchAssignedDevices);

	yield takeLatest(groupEntityActions.updateAssignedDevices, updateAssignedDevices);
	yield takeLatest(groupEntityActions.updateAssignedDevicesSuccess, updateAssignedDevicesSuccess);
	yield takeLatest(groupEntityActions.updateAssignedDevicesFailure, updateAssignedDevicesFailure);

	yield takeLatest(groupEntityActions.updateAssignedDevicesWithAllMode, updateAssignedDevicesWithAllMode);
	yield takeLatest(groupEntityActions.updateAssignedDevicesWithAllModeSuccess, updateAssignedDevicesSuccess);
	yield takeLatest(groupEntityActions.updateAssignedDevicesWithAllModeFailure, updateAssignedDevicesFailure);
  	// yield takeLatest(groupEntityActions.updatePreferencesFailure, onUpdatePreferencesFailure);
  
  	yield takeLatest(groupEntityActions.fetchVersions, fetchVersions);
  	yield takeLatest(groupEntityActions.publishVersionToGroup, publishVersionToGroup);
  	yield takeLatest(groupEntityActions.publishVersionToGroupSuccess, publishVersionToGroupSuccess);
  	yield takeLatest(groupEntityActions.publishVersionToGroupFailure, publishVersionToGroupFailure);
  	// yield takeLatest(groupEntityActions.fetchVersionsFailure, fetchVersionsFailure); // TODO: need to handle it. UX is TBD
	
  	yield takeLatest(groupEntityActions.fetchGroupDevices, fetchGroupDevices);
}