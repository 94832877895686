import React, { useEffect, useState } from 'react';
import { Layout, Spin, Avatar, Typography, Dropdown, Menu, Button } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import {
	Spacer,
	StyledHeader,
	MainContent,
	CollapseIcon,
	HeaderComponentsBox,
	SpinBox,
	StyledLayout,
} from './AppLayout.styled';
import Logo from 'components/Logo';
import DevicesPage from 'pages/Devices';
import HomePage from 'pages/HomePage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import SideMenu from 'containers/App/components/SideMenu';
import TestPage from 'pages/TestPage';
import UsersPage from 'pages/Users';
import Qlik from 'pages/Qlik';
import PreferencesPage from 'pages/Preferences';
import AlarmsPage from 'pages/Alarms';
import GroupsPage from 'pages/Groups';
import SwVersionsPage from 'pages/SwVersions';
import LumenisXVersionsPage from 'pages/LumenisXVersions';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';

const PADDED_PAGE_CONTENT = "24px 24px 0px 24px";
const NOT_PADDED_PAGE_CONTENT = "0px 0px 0px 0px";
const { Sider } = Layout;
const MenuLogo = styled(Logo)`
	height: 60px;
    padding: 8px 8px 8px 0;
    margin: 0;
`;

const AppLayout = (props) => {
  const {
    isAppLoading,
    loginWithToken,
    userName,
	logout,
	location
  } = props;
	const [collapsed, setCollapsed] = useState(false);

	useEffect(() => {
		loginWithToken();
	}, []);

	const toggle = () => {
		setCollapsed(!collapsed)
  };
  
  const navigateToPreferences = () =>{
    props.push('/preferences');
  }

  // <Menu.Item>
	// 			<Button type="link" onClick={navigateToPreferences}>User Preferences</Button>
	// 		</Menu.Item>
	const userMenu = (
		<Menu>
			
			<Menu.Item>
				<Button type="link" onClick={logout}>Logout</Button>
			</Menu.Item>
		</Menu>
	);

	return isAppLoading ? <SpinBox centered><Spin spinning={true} size="large" /></SpinBox> :
		(
			<StyledLayout>
				<Sider trigger={null} collapsible collapsed={collapsed}>
					<MenuLogo small={collapsed} />
					<SideMenu />
				</Sider>
				<Layout>
					<StyledHeader>
						<HeaderComponentsBox gutter={12} hAligned>
							<CollapseIcon
								className="trigger"
								type={collapsed ? 'menu-unfold' : 'menu-fold'}
								onClick={toggle}
							/>
							<Spacer />
							<Typography.Text>{userName}</Typography.Text>
							<Dropdown overlay={userMenu}>
								<Avatar icon="user" />
							</Dropdown>
						</HeaderComponentsBox>


					</StyledHeader>
					<MainContent padding={location.pathname === "/home" ? NOT_PADDED_PAGE_CONTENT : PADDED_PAGE_CONTENT }>
						<Switch>
							<Route exact path="/">
								<Redirect to="/home" />
							</Route>
							<Route path="/home" component={HomePage} />
							{
								//todo-permissions: need to decouple permissions from UI! VerifyChildPermissions caused problems here
								hasPermission(PERMISSIONS.UI_VIEW_USERS) &&
								<Route path="/users" component={UsersPage.component} />
							}
							{
								hasPermission(PERMISSIONS.UI_VIEW_DEVICES) &&
								<Route path="/devices" component={DevicesPage.component} />
							}
							<Route path="/qlik" component={Qlik.component} />
							<Route path="/preferences" component={PreferencesPage.component} />
							<Route path="/alarms" component={AlarmsPage.component} />
							<Route path="/groups" component={GroupsPage.component} />
							<Route path="/swVersions" component={SwVersionsPage.component} />
							<Route path="/cloudSDKVersions" component={LumenisXVersionsPage.component} />
							<Route path="/test" component={TestPage} />
							<Route path="" component={NotFoundPage} />
						</Switch>
					</MainContent>
				</Layout>
			</StyledLayout>
		)
};

AppLayout.displayName = 'AppLayout';
export default React.memo(AppLayout);