import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { deviceEntityActions } from 'containers/App/modules/entities/devices/deviceEntity.slice';
import { DEFAULT_LOADING_PROP } from 'containers/App/modules/redux.contants';
import { createResetGridDataAction, createResetGridReducer, createSetGridParamsAction, createSetGridParamsReducer, createUpdateGridReducer, GRID_INITIAL_STATE } from 'containers/Grid/grid.utils';


const DEVICES_STATE_KEY = 'devices';
const DEVICES_GRID_STATE_KEY = 'devicesGrid';
const setGridParams = createSetGridParamsAction(DEVICES_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(DEVICES_GRID_STATE_KEY);

export const INITIAL_STATE = {
	assignedUsers:            undefined,
	customerDetails:          undefined,
	[DEVICES_GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const setAssignedUsersStateFn = (state, action) => {
	state.assignedUsers = action.payload.data;
};

const devicesSlice = createSlice({
	name:          DEVICES_STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		setAssignedUsers: (state, action) => {
			state.assignedUsers = action.payload;
		},

		clearEditData: (state) => {
			delete state.assignedUsers;
			delete state.customerDetails;
		},
	},
	extraReducers: {
		[deviceEntityActions.fetchAssignedUsersSuccess]: setAssignedUsersStateFn,

		//GRID
		[deviceEntityActions.fetchDevicesSuccess]: createUpdateGridReducer(DEVICES_GRID_STATE_KEY),
		[setGridParams]:                           createSetGridParamsReducer(DEVICES_GRID_STATE_KEY),
		[resetGridData]:                           createResetGridReducer(DEVICES_GRID_STATE_KEY),
	}
});


const getDevicesState = state => state[DEVICES_STATE_KEY] || INITIAL_STATE;

const selectEditedDeviceId = createSelector(
	getDevicesState,
	devicesState => _.get(devicesState, 'editedDeviceId')
);

const selectEditedDevicePotentialManagersIds = createSelector(
	getDevicesState,
	devicesState => _.get(devicesState, 'potentialManagers')
);

const selectPotentialDevicesPending = createSelector(
	getDevicesState,
	devicesState => _.get(devicesState, DEFAULT_LOADING_PROP)
);

const selectDevicesGrid = createSelector(
	getDevicesState,
	devicesState => {
		return devicesState[DEVICES_GRID_STATE_KEY]
	}
);

const selectDeviceIdsForPage = (state, props) => {
	const gridState = selectDevicesGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

const selectAssignedUsers = createSelector(
	getDevicesState,
	devicesState => devicesState.assignedUsers
);

const selectAssignedUsersPending = createSelector(
	getDevicesState,
	devicesState => devicesState.assignedUsersPending
);

export const deviceSelectors = {
	selectEditedDeviceId,
	selectEditedDevicePotentialManagersIds,
	selectPotentialDevicesPending,
	selectDevicesGrid,
	selectDeviceIdsForPage,
	selectAssignedUsers
};

const {
	      clearEditedDevice,
	      setAssignedUsers,
	      clearEditData
      } = devicesSlice.actions;
export const devicesActions = {
	clearEditedDevice,
	setAssignedUsers,
	clearEditData,
	setGridParams,
	resetGridData
};
export default devicesSlice.reducer;