import { defineMessages } from "react-intl";

export const scope = "swVersions";

export default defineMessages({
	type: {
		id: `${scope}.type`,
		defaultMessage: "Type",
	},
	version: {
		id: `${scope}.version`,
		defaultMessage: "Version",
	},
	uploadDate: {
		id: `${scope}.uploadDate`,
		defaultMessage: "Upload Date",
	},
	uploadedBy: {
		id: `${scope}.uploadedBy`,
		defaultMessage: "Uploaded By",
	},
	upload: {
		id: `${scope}.upload`,
		defaultMessage: "Upload",
	},
	comments: {
		id: `${scope}.comments`,
		defaultMessage: "Comments",
	},
	uploadVersion: {
		id: `${scope}.uploadVersion`,
		defaultMessage: "Upload Software Version",
	},
});
