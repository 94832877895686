/* eslint import/no-mutable-exports: 0 */
/* eslint prefer-const: 0 */

import DevConfig from './DevConfig';

let PROD_CONFIG = {
  ...DevConfig,
  API_URL: 'https://services.pet-novations.com',
  // API_URL: 'https://iot.petnovations.com',
};

export default PROD_CONFIG;
