export const MODAL_TYPES = {
	CREATE_USER_MODAL: "createUserModal",
	CREATE_DEVICE_MODAL: "createDeviceModal",
	DEVICE_PROPERTIES_MODAL: "devicePropertiesModal",
	CREATE_GROUP_MODAL: "createGroupModal",
	UPLOAD_LUMENISX_VERSION_MODAL: "uploadLumenisXVersionModal",
	UPLOAD_SW_VERISON_MODAL: 'uploadSwVersionModal',
	ASSIGN_DEVICES_TO_GROUP_MODAL: 'assignDevicesToGroupModal',
	UPDATE_GROUP_VERSIONS_MODAL: 'updateGroupVersionsModal',
	GROUP_DEVICES_STATUS_MODAL: 'groupDevicesStatusModel'
};
