import { userNameRenderer } from "containers/Grid/components/ColumnRenderers";

export const deviceGridColumns = [
	{
		key: "deviceInfo.deviceSerialNumber",
		title: "Serial Number",
		dataIndex: "deviceSerialNumber",
		sorter: true,
	},
	{
		key: "deviceInfo.deviceType",
		title: "Device Type",
		dataIndex: "deviceType.type",
		sorter: true,
	},
	{
		key: "customerDetails.firstName",
		title: "Customer",
		dataIndex: "customerDetails",
		render: userNameRenderer,
		sorter: true,
	},
	{
		key: "customerDetails.address.region",
		title: "Region",
		dataIndex: "customerDetails.address.region",
		sorter: true,
	},
	{
		key: "customerDetails.address.country",
		title: "Country",
		dataIndex: "customerDetails.address.country",
		sorter: true,
	},
];