import { connect } from 'react-redux';
import { compose } from 'redux';

import DeviceProperties from '../components/DeviceProperties';
import { deviceEntityActions, deviceEntitySelectors } from 'containers/App/modules/entities/devices/deviceEntity.slice';
import { devicesActions, deviceSelectors } from 'pages/Devices/modules/devices.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import entitySchemas from 'containers/App/modules/api/schema';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';


const mapStateToProps = (state, ownProps) => {
	const editedDeviceId = ownProps.deviceId;
	const device = dbSelectors.selectEntitiesDenormalized(entitySchemas.device, editedDeviceId)(state);

	const assignedUsers = deviceSelectors.selectAssignedUsers(state);
	const canAssignUsers = hasPermission(PERMISSIONS.UI_ASSIGN_USERS_FOR_DEVICE);

	const fetchAssignedUsersPending = deviceEntitySelectors.selectFetchAssignedUsersPending(state);
	const updateAssignedUsersPending = deviceEntitySelectors.selectUpdateAssignedUsersPending(state);
	const fetchCustomerDetailsPending = deviceEntitySelectors.selectFetchCustomerDetailsPending(state);
	const updateCustomerDetailsPending = deviceEntitySelectors.selectUpdateCustomerDetailsPending(state);
	const isLoading = fetchAssignedUsersPending || fetchCustomerDetailsPending;
	const selectDisableDevicePending = deviceEntitySelectors.selectDisableDevicePending(state);

	return {
		isLoading,
		assignedUsers,
		device,
		canAssignUsers,
        isDisableDeviceLoading: !!selectDisableDevicePending
	};
};

const withConnect = connect(mapStateToProps, { ...devicesActions, ...deviceEntityActions });

export default compose(
	withConnect
)(DeviceProperties);