import { schema } from 'normalizr';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';


const userGroup = new schema.Entity(ENTITY_TYPES.USER_GROUPS);
const country = new schema.Entity(ENTITY_TYPES.COUNTRIES);
const deviceType = new schema.Entity(ENTITY_TYPES.DEVICE_TYPES, {}, {
	idAttribute: (value, parent, key) => value.type
});

const user = new schema.Entity(ENTITY_TYPES.USERS, {
	group: userGroup
}, {
	/*mergeStrategy: (entityA, entityB) => {
		console.log(entityA, entityB);
		return {
			...entityA,
			...entityB,
		}
	}*/
});

user.define({
	manager: user
});

const customerDetails = new schema.Entity(ENTITY_TYPES.CUSTOMER_DETAILS);

const device = new schema.Entity(ENTITY_TYPES.DEVICES, {
	customerDetails: customerDetails,
	deviceType:      deviceType
});

const group = new schema.Entity('groups');

const swVersion = new schema.Entity('swVersions');

const lumenisXVersion = new schema.Entity('lumenisXVersions');

const entitySchemas = {
	userGroup,
	country,
	deviceType,
	user,
	customerDetails,
	device,
	group,
	swVersion,
	lumenisXVersion
};

export default entitySchemas;
