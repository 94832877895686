import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ALL_VALUE } from 'containers/App/app.constants';
import { SORT_TYPES_MAP } from 'components/DataGrid/dataGrid.constants';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';


export const parseFetchAllRequest = payload => {
	if (!payload) return payload;
    const { pagination, sorter, filters, search, additionalData } = payload;
	const { current, pageSize } = pagination;

    const nonEmptyFilters = _.omitBy(filters, _.isNil); //server cannot handle empty values
	const filter = _.map(nonEmptyFilters, (value, key) => ({
		values: value,
		prop:   key
	}));
	return {
		limit: pageSize,
		page:  current - 1,
		search,
		filter,
		sort:  !_.isEmpty(sorter) ? [{
			prop: sorter.columnKey || sorter.field,
            order:  SORT_TYPES_MAP[sorter.order]
        }] : undefined,
        ...additionalData,
	}
};

export const parseFetchAllSuccess = responseData => {
    return _.map(responseData.data, user => {
		const {
			      groupId,
			      groupName,
			      managerId,
			      managerFirstName,
			      managerLastName,
			      ...restOfUser
		      } = user;
		return {
			...restOfUser,
			group:   {
				id:   groupId,
				name: groupName
			},
			manager: {
				id:        managerId,
				firstName: managerFirstName,
				lastName:  managerLastName,
			}
		}
	});
};

export const parseFetchByIdSuccess = responseData => {
	const {
		      groups = [],
		      manager,
		      assignedResources,
		      ...restOfUser
	      } = responseData.data;

	return {
		group:   groups[0],
		manager: manager.userResponse,
		devices: _.map(assignedResources, resource => ({
			id:          uuidv4(),
			deviceTypes: resource.deviceTypeItems,
			locations:   resource.locationTypeItems,
		})),
		...restOfUser
	}
};

export const parseCreateRequest = payload => {
	const { devices, locked, ...restOfUser } = payload;
	const user = {
		locked,
		resources: {
			assignedResourceGroupList: _.map(devices, deviceLocationPair => {
				return {
					deviceTypeItems:   deviceLocationPair.deviceTypes,
					locationTypeItems: deviceLocationPair.locations
				}
			}),
		},
		user:      {
			groupId:   payload.group,
			phone:     payload.phoneNumber,
			managerId: payload.manager,
			...restOfUser
		}
	};
	return user;
};
export const parseCreateSuccess = payload => {
	const { data, requestParams } = payload;
	const { userDetails: { userId } } = data;
	const { resources, user } = requestParams;

	return {
		id:      userId,
		devices: resources && resources.list ?
			         resources.list.map(resource => ({
				         device: { id: resource.deviceTypeId },
				         region: resource.region
			         })) : [],
		...user,
	}
};

export const parseUpdateRequest = payload => {
	const { userId, userValues } = payload;
	const apiUser = parseCreateRequest(userValues);
	return {
		userId,
		data: apiUser
	};
};

export const parseUpdateSuccess = payload => {
	return {
		id: payload.requestParams.userId,
		//todo cannot update user store. responses are different and request is missing data like user group or manager objects.
		// need to normalize data for updates or fetch users again automatically
	}
};


export const parseFetchPotentialManagersSuccess = response => {
	return _.map(response.lumUsersSet, userObj => userObj.userResponse)
};
