import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import _ from 'lodash';
import { push } from 'connected-react-router';

import { loginActions } from 'pages/LoginPage/modules/login.slice';


import { selectLoginIsLoading } from 'pages/LoginPage/modules/login.selectors';
import deviceTypesSaga from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.saga';
import userGroupsSaga from 'containers/App/modules/entities/userGroups/userGroupEntity.saga';
import countriesSaga from 'containers/App/modules/entities/countries/countries.saga';
import userSaga from 'containers/App/modules/entities/users/userEntity.saga';
import alarmsSaga from 'containers/App/modules/entities/alarms/alarmsEntity.saga';
import groupsSaga from 'containers/App/modules/entities/groups/groupEntity.saga';
import swVersionsSaga from 'containers/App/modules/entities/swVersions/swVersionEntity.saga';
import lumenisXVersionsSaga from 'containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.saga';
import AppLayout from 'containers/App/components/AppLayout';
import { countriesSelectors } from 'containers/App/modules/entities/countries/countries.slice';
import { deviceTypesSelectors } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import { userGroupSelectors } from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import { userEntitySelectors } from 'containers/App/modules/entities/users/userEntity.slice';


const mapStateToProps = (state) => {
	const loginLoading = selectLoginIsLoading(state);
	const countriesLoading = countriesSelectors.selectCountriesPending(state);
	const deviceTypesLoading = deviceTypesSelectors.selectDeviceTypesPending(state);
	const userGroupsLoading = userGroupSelectors.selectUserGroupsPending(state);
	const fetchLoggedInUserPending = userEntitySelectors.selectFetchLoggedInUserPending(state);
	const fetchDeviceTypesHierarchyLoading = deviceTypesSelectors.selectDeviceTypesHierarchyLoading(state);
	const fetchLocationsHierarchyLoading = countriesSelectors.selectLocationsHierarchyLoading(state);

	const appStartActionLoading =
		      loginLoading ||
		      countriesLoading ||
		      deviceTypesLoading ||
		      userGroupsLoading ||
		      fetchLoggedInUserPending ||
		      fetchDeviceTypesHierarchyLoading ||
		      fetchLocationsHierarchyLoading;

	//todo so children won't be rendered when all are still undefined. basically i need to keep a separate redux var for that like appLoading = true by default so children won't render
	const isAppLoading = !_.isUndefined(appStartActionLoading) ? appStartActionLoading : true;

	const userName = `${state.userManagementReducer.firstName} ${state.userManagementReducer.lastName}`;
	return {
		isAppLoading,
		userName
	};
};

const withConnect = connect(mapStateToProps, { ...loginActions, push });

const withDeviceTypesSaga = injectSaga({
	key:  'deviceTypesSaga',
	saga: deviceTypesSaga,
	mode: DAEMON,
});

const withCountriesSaga = injectSaga({
	key:  'countriesSaga',
	saga: countriesSaga,
	mode: DAEMON,
});

const withUserGroupsSaga = injectSaga({
	key:  'userGroupsSaga',
	saga: userGroupsSaga,
	mode: DAEMON,
});

const withUsersSaga = injectSaga({
	key:  'userSaga',
	saga: userSaga,
	mode: DAEMON,
});

const withAlarmsSaga = injectSaga({
	key:  'alarmsSaga',
	saga: alarmsSaga,
	mode: DAEMON,
});

const withGroupsSaga = injectSaga({
	key:  'groupsSaga',
	saga: groupsSaga,
	mode: DAEMON,
});

const withSwVersionsSaga = injectSaga({
	key:  'swVersionsSaga',
	saga: swVersionsSaga,
	mode: DAEMON,
});

const withLumenisXVersionsSaga = injectSaga({
	key:  'lumenisXVersionsSaga',
	saga: lumenisXVersionsSaga,
	mode: DAEMON,
});

export default compose(
	withUsersSaga,
	withDeviceTypesSaga,
	withCountriesSaga,
	withUserGroupsSaga,
	withAlarmsSaga,
	withGroupsSaga,
	withSwVersionsSaga,
	withLumenisXVersionsSaga,
	withConnect,
)(AppLayout);