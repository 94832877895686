import { defineMessages } from 'react-intl';

export const scope = 'entities';

export default defineMessages({
	createEntitySuccess: {
		id: `${scope}.createEntitySuccess`,
		defaultMessage: 'Create {entityType} successful',
		defaultValues: {
			entityType: 'kaka'
		}
	},
	updateEntitySuccess: {
		id: `${scope}.updateEntitySuccess`,
		defaultMessage: 'Update entityType successful',
	},
	users: {
		id: `${scope}.users`,
		defaultMessage: 'Users',
	},
	devices: {
		id: `${scope}.devices`,
		defaultMessage: 'Devices',
	},
	qlik: {
		id: `${scope}.qlik`,
		defaultMessage: 'Qlik',
	},
});
