import { takeLatest, put, all } from 'redux-saga/effects';
import { appActions } from 'containers/App/modules/app.slice';
import { deviceTypesActions } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice'
import { countriesActions } from 'containers/App/modules/entities/countries/countries.slice';
import { userGroupsActions } from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';


function* appStart(action) {
	const { userId } = action.payload;
	const actions = deviceTypesActions;
		yield all([
		put(userEntityActions.fetchLoggedInUser(userId)),
		put(countriesActions.fetchCountries()),
		put(countriesActions.fetchLocationsHierarchy()),
		put(deviceTypesActions.fetchDeviceTypes()),
		put(deviceTypesActions.fetchDeviceTypesHierarchy()),
		put(userGroupsActions.fetchUserGroups())
	]);
}

export default function* watchAppSaga() {
	yield takeLatest(appActions.appStart, appStart);
};