/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userManagementReducer from 'softimize-client-user-management/dist/modules/reducers';

import history from 'utils/history';
import languageProviderReducer, { LANGUAGE_STATE_KEY } from 'containers/LanguageProvider/reducer';
import asyncReducer, { ASYNC_STATE_KEY } from 'containers/App/modules/async.reducer';
import entitiesReducer from 'containers/App/modules/entities/entities.slice';
import modalReducer, { MODAL_STATE_KEY } from 'containers/ModalRoot/modules/modal.slice';
import { loginActions } from 'pages/LoginPage/modules/login.slice';
import { ENTITIES_STATE_KEY } from 'containers/App/modules/entities/entities.constants';
import appReducer, { APP_STATE_KEY } from 'containers/App/modules/app.slice';


const staticReducers = {
	[LANGUAGE_STATE_KEY]: languageProviderReducer,
	router:               connectRouter(history),
	[ASYNC_STATE_KEY]:    asyncReducer,
	[ENTITIES_STATE_KEY]: entitiesReducer,
	[MODAL_STATE_KEY]:    modalReducer,
	[APP_STATE_KEY]:      appReducer,
	userManagementReducer,
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {

	const appReducer = combineReducers({
		...staticReducers,
		...injectedReducers,
	});

	//rootReducer in charge of resetting the store on logout.
	//see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
	const rootReducer = (state, action) => {
		if (action.type === loginActions.logoutSuccess) {
			const { router } = state;
			state = { router };
		}
		return appReducer(state, action);
	};

	return rootReducer;
}
