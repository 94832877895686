import React from "react";
import styled from 'styled-components';
import FormFactory from "components/FormFactory";
import { Col, Row, Select, Input } from "antd";
import { withErrorBoundary } from "components/ErrorBoundary";
import SelectField from 'components/FormFactory/fields/SelectField';
import TextField from 'components/FormFactory/fields/TextField';
import _ from 'lodash';

// const { Option } = Select;
const DisabledTextField = styled(TextField)`
  color: #000000 !important;
`;

const Form = FormFactory.create({
	name: "updateGroupVersionForm"
});

//TODO: change to functional component
class UpdateGroupVersions extends React.Component {

  componentDidMount() {
    this.props.fetchVersions({groupId: this.props.group.id, deviceType:`${this.props.group.device.family}/${this.props.group.device.type}`});
  }

	publishUpdate = (versionValues) => {
    this.props.publishVersionToGroup(versionValues);
	};

	onCancel = (form) => {
		this.props.hideModal();
	};

	render() {
    const { isCreateUpdateLoading } = this.props;
    const swVersions = !!this.props.versions && _.uniqBy(this.props.versions.data.softwareVersions, (version) => version.value);
    const lumXVersions = !!this.props.versions && _.uniqBy(this.props.versions.data.lumenisXVersions, (version) => version.value);
		return (
			<Form
				submitButtonText="Publish Update"
				hideSubmitButton={false}
				onSubmit={this.publishUpdate}
				isLoading={isCreateUpdateLoading}
				hideCancelButton={false}
				onCancel={this.onCancel}
			>
				{(form) => {
          const swVersionValue = form.getFieldValue('softwareVersion')
          const lumXVersionValue = form.getFieldValue('lumenisXVersion')

          const isSwVersionsRequired = !lumXVersionValue;
          const isLumXVersionsRequired = !swVersionValue;

          return(
					<Row gutter={24}>
            <Col span={12}>
              <DisabledTextField name="groupName"
                label="Group Name"
                initialValue={this.props.group.name}
                disabled={true}
              />

              <SelectField name="softwareVersion"
                label="Software Version"
                required={isSwVersionsRequired}
                loading={this.props.isFetchVersionsLoading}>
                {
                  swVersions && swVersions.map((version, i) => {
                    return <Select.Option key={i} value={version.id}>{version.value}</Select.Option>;
                  })
                }
              </SelectField>
              
              <SelectField name="lumenisXVersion"
                label="Cloud SDK Version"
                required={isLumXVersionsRequired}
                loading={this.props.isFetchVersionsLoading}>
                {
                  lumXVersions && lumXVersions.map((version, i) => {
                    return <Select.Option key={i} value={version.id}>{version.value}</Select.Option>;
                  })
                }
              </SelectField>
						</Col>
					</Row>
				)}}
			</Form>
		);
	}
}

export default withErrorBoundary(UpdateGroupVersions);
