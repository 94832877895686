import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import FormField from 'components/FormFactory/FormField';


const SelectField = (props) => {
	const {options, children, ...rest} = props;
	return (
		<FormField {...rest}>
			<Select>{children || options}</Select>
		</FormField>
	);
};

SelectField.propTypes = {
	...FormField.propTypes
};

export default SelectField;