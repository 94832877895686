import { call, put, takeLatest } from 'redux-saga/effects';
import UserManagement from "softimize-client-user-management";
import { push } from 'connected-react-router'
import { loginActions } from 'pages/LoginPage/modules/login.slice';
import {  api } from 'containers/App/modules/async.saga';
import { appActions } from 'containers/App/modules/app.slice';
import { STORAGE_LOGIN_DATA_KEY } from 'containers/App/app.constants';
import { errorToast } from 'containers/App/modules/entities/entities.utils';
import _ from 'lodash';


export function* login(action) {
	const { username, password, remember } = action.payload;

	yield call(api, {
		apiFn:   [UserManagement, UserManagement.login],
		params:  {
			user:       username,
			password:   password,
			rememberMe: remember
		},
		actions: [
			loginActions.loginRequest,
			loginActions.loginSuccess,
			loginActions.loginFailure,
		]
	});
}

function* loginSuccess(action) {
	//TODO: UGLY WORKAROUND. session management bug in UserManagementService.
	const userData = JSON.stringify(_.get(action, 'payload.data'));
	sessionStorage.setItem(STORAGE_LOGIN_DATA_KEY, userData);

	yield put(push('/'));
}

function* loginFailure(action) {
	yield errorToast(action, 'Login Failure');
}

function* loginWithTokenSaga() {
	yield call(api, {
		apiFn:   [UserManagement, UserManagement.loginWithToken],
		actions: [
			loginActions.loginWithTokenRequest,
			loginActions.loginWithTokenSuccess,
			loginActions.loginWithTokenFailure
		]
	});
}

function* loginWithTokenFailure() {
	yield put(push('/login'));
}

function* loginWithTokenSuccess(action) {
	const userData = _.get(action, 'payload.data');
	const userDataString = JSON.stringify(userData);
	sessionStorage.setItem(STORAGE_LOGIN_DATA_KEY, userDataString);
	yield put(appActions.appStart(userData));
}

function* logout() {
	console.log(`logout`)
	yield call(api, {
		apiFn:   [UserManagement, UserManagement.logout],
		actions: [
			loginActions.logoutRequest,
			loginActions.logoutSuccess,
			loginActions.logoutFailure
		]
	});
}

function* logoutSuccess() {
	//TODO: UGLY WORKAROUND. session management bug in UserManagementService.
	sessionStorage.removeItem(STORAGE_LOGIN_DATA_KEY);

	yield put(push('/login'));
}

export default function* watchLoginSaga() {
	yield takeLatest(loginActions.login, login);
	yield takeLatest(loginActions.loginSuccess, loginSuccess);
	yield takeLatest(loginActions.loginFailure, loginFailure);

	yield takeLatest(loginActions.loginWithToken, loginWithTokenSaga);
	yield takeLatest(loginActions.loginWithTokenFailure, loginWithTokenFailure);
	yield takeLatest(loginActions.loginWithTokenSuccess, loginWithTokenSuccess);

	yield takeLatest(loginActions.logout, logout);
	yield takeLatest(loginActions.logoutSuccess, logoutSuccess);


}