import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Spin, Tabs } from 'antd';
import _ from 'lodash';

import DeviceFormContainer from 'pages/Devices/containers/DeviceFormContainer';
import UsersGrid from 'pages/Users/containers/UsersGridContainer';
import DevicePropertiesTab from 'pages/Devices/components/DevicePropertiesTab';
import DisableDeviceTab from 'pages/Devices/components/DisableDeviceTab';
import AlarmsGrid from 'pages/Alarms/containers/Container';


const DeviceTabs = styled(Tabs)`
	height: 750px;
`;

const UpdateAssignedUsersButton = styled(Button).attrs({ type: 'primary' })`
	float: right;
`;

const { TabPane } = Tabs;

const DeviceProperties = (props) => {
	const {
		      deviceId,
		      device,
		      assignedUsers,
		      canAssignUsers,
		      fetchAssignedUsers,
		      fetchDeviceProperties,
		      setAssignedUsers,
		      updateAssignedUsers,
		      fetchCustomerDetails,
		      clearEditData,
		      isLoading,
	      } = props;

	const selectedRowKeys = _.map(assignedUsers, user => user.userId);
	const implicitAssignedUsers = _.filter(assignedUsers, user => !user.explicitAssign);
	const implicitAssignedUsersIds = _.map(implicitAssignedUsers, user => user.userId); //implicit = users assigned due to ALL value, not explicitly for this device
	const explicitAssignedUserIds = _.difference(selectedRowKeys, implicitAssignedUsersIds);

	useEffect(() => {
		if (device) {
			const { deviceSerialNumber } = device;
			fetchAssignedUsers({ deviceSerialNumber });
			fetchCustomerDetails({ deviceSerialNumber });
			fetchDeviceProperties({  deviceSerialNumber });
		}
	}, [deviceId]);

	useEffect(() => () => {
		clearEditData();
	}, []);

	const onUpdateUserAssignment = () => {
		const {  deviceSerialNumber } = device;
		updateAssignedUsers({ deviceSerialNumber, explicitAssignedUserIds });
	};

	const usersGridcolumns = [
		{
			key:    'firstName',
			title:  'Name',
			render: (text, row) => `${row.firstName} ${row.lastName}`,
			sorter: true,
		},
		{
			key:       'group.name',
			dataIndex: 'group.name',
			title:     'User Group',
		},
  ];
  
  const alarmsGridColumns = [
    {
      key:       'time',
      title:     'Time',
      dataIndex: 'time',
      sorter:    true
    },
    {
      key:       'alarmId',
      title:     'Alarm Id',
      dataIndex: 'alarmId',
      sorter:    true
    },
    {
      key:       'description',
      title:     'Description',
      dataIndex: 'description',
    },
    {
      key:       'status',
      title:     'Status',
      dataIndex: 'stats',
      render:    (status) => status === 10 ? 'OK' : 'Alarm',
    },
  ];

	const onSelectionChange = (selectedRowKeys, selectedRows) => {
		const assignedUsers = _.map(selectedRowKeys, rowKey => ({
				userId:         rowKey,
				explicitAssign: !_.includes(implicitAssignedUsersIds, rowKey)
			})
		);
		setAssignedUsers(assignedUsers);
	};


	const rowSelection = {
		columnTitle:           'Assign',
		columnWidth:           100,
		onChange:              onSelectionChange,
		selectedRowKeys,
		getCheckboxProps:      record => ({
			disabled: _.includes(implicitAssignedUsersIds, record.id) || !canAssignUsers,
			name:     record.name,
		}),
		hideDefaultSelections: true
	};

	return (
		<Spin spinning={isLoading}>
			<DeviceTabs className={props.className}
			            tabPosition="left">
				<TabPane tab="General" key="general">
					<DeviceFormContainer device={device} />
				</TabPane>

				<TabPane tab="Properties" key="properties">
          <DevicePropertiesTab device={device} />
				</TabPane>
				

				<TabPane tab="Assign" key="assign">
					<UsersGrid
						showAddNew={false}
						columns={usersGridcolumns}
						rowSelection={rowSelection}
					/>

					{
						canAssignUsers &&
						<UpdateAssignedUsersButton
							onClick={onUpdateUserAssignment}>
							Update User Assignment
						</UpdateAssignedUsersButton>
					}
				</TabPane>

		 <TabPane tab="Alarm History" key="history">
           <AlarmsGrid
             showAddNew={false}
             columns={alarmsGridColumns}
           />
         </TabPane>
        
        <TabPane tab="Disable" key="disable">
          <DisableDeviceTab 
            device={device} 
            disableDevice={props.disableDevice} 
            isDisableDeviceLoading={props.isDisableDeviceLoading}
          />
				</TabPane>
			</DeviceTabs>
		</Spin>
	);
};

DeviceProperties.propTypes = {
  device: PropTypes.object,
  disableDevice: PropTypes.func.isRequired,
  isDisableDeviceLoading: PropTypes.bool.isRequired,
};

export default DeviceProperties;