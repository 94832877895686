import React from 'react';
import styled from 'styled-components';

const IFrame = styled.section`
	width: 100%;
	height: 100%;
`;

const Qlik = () => {
	const iframeHtmlString =
		      `<iframe src="https://qlik.dev.lumenisx.lumenis.com:8080/sense/app/1e38c2d0-5071-483e-a421-aab5584dd1e6/sheet/CBNEVV/state/analysis" width="100%" height="100%"></iframe>`;
	return (
		<IFrame dangerouslySetInnerHTML={{ __html: iframeHtmlString }} />
	);
};

export default Qlik;