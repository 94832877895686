import ApiService from 'softimize-apiservice';
import UserManagement from 'softimize-client-user-management';
import AppConfig from '../config/AppConfig';
import qs from 'qs';
import MockResponses from './MockResponse';

ApiService.Config = {
	baseURL:              AppConfig.API_URL,
	tokenHandler:         UserManagement,
	refreshTokenResource: {
		baseURL:     `${AppConfig.API_URL}/ums/v1`,
		uri:         'users/current/refreshToken',
		method:      'POST',
		requireAuth: false,
	},
};

const paramsSerializer = params => {
	return qs.stringify(params, { allowDots: true })
};

const Resources = {
	fetchUsers:              {
		uri:              'search/v1/user',
		method:           'GET',
		requireAuth:      true,
		paramsSerializer
	},
	addUser:                 {
		uri:         'facade/v1/user',
		method:      'POST',
		requireAuth: true,
	},
	fetchUserGroups:         {
		uri:         'organization/v1/userGroups/subordinates',
		method:      'GET',
		requireAuth: true,
	},
	updateUser:              {
		uri:         'facade/v1/user',
		method:      'PUT',
		requireAuth: true,
	},
	fetchDeviceTypes:        {
		uri:         'device/v1/lumDeviceTypes',
		method:      'GET',
		requireAuth: true,
	},
	disableDevice:           {
		uri:         'device/v1/lumDevice/disable',
		method:      'PUT',
		requireAuth: true,
	},
	getDevices:              {
		uri:              'search/v1/device',
		method:           'GET',
		requireAuth:      true,
		paramsSerializer
	},
	addDevice:               {
		uri:         'facade/v1/device',
		method:      'POST',
		requireAuth: true,
	},
	getCountries:            {
		uri:         'organization/v1/country/all',
		method:      'GET',
		requireAuth: true,
	},
	fetchManagers:           {
		uri:         'organization/v1/user/potentialManagers',
		method:      'GET',
		requireAuth: true,
	},
	fetchUser:               {
		uri:         'facade/v1/user',
		method:      'GET',
		requireAuth: true,
	},
	fetchAssignedUsers:      {
		uri:         'organization/v1/device/types',
		method:      'GET',
		requireAuth: true,
	},
	updateAssignedUsers:     {
		uri:         'organization/v1/device/types',
		method:      'POST',
		requireAuth: true,
	},
	fetchCustomerDetails:    {
		uri:         'organization/v1/customers/types',
		method:      'GET',
		requireAuth: true,
	},
	updateCustomerDetails:   {
		uri:         'organization/v1/customers/types',
		method:      'POST',
		requireAuth: true,
	},
	getDeviceProperties:     {
		uri:         'facade/v1/device/properties/deviceTypes',
		method:      'GET',
		requireAuth: true,
	},
	resetPassword:           {
		uri:         'ums/v1/admin/users',
		method:      'PUT',
		requireAuth: true,
	},
	getLocationsHierarchy:   {
		uri:         'organization/v1/country/hierarchy',
		method:      'GET',
		requireAuth: true,
	},
	getDeviceTypesHierarchy: {
		uri:         'device/v1/lumDeviceTypes/hierarchy',
		method:      'GET',
		requireAuth: true,
	},
	fetchGroups:             {
		uri:         'mock',
		method:      'GET',
		requireAuth: true,
		paramsSerializer
	},
	fetchGroup:              {
		uri:         '',
		method:      'GET',
		requireAuth: true,
	},
	createGroup:             {
		uri:         'mock',
		method:      'POST',
		requireAuth: true,
	},
	updateGroup:             {
		uri:         '',
		method:      'PUT',
		requireAuth: true,
	},
};

function fetchAlarmsMock() {
	return Promise.resolve({
		"metadata": {
			"page": {
				"totalResults": 2,
				"page":        0,
				"limit":       10
			},
			"sort": [
				{
					"prop": "date",
					"order":  "DESC"
				}
			]
		},
		"data":     [
			{
				"id":          "1",
				"time":        "1",
				"alarmId":     263,
				"description": "CPU High Usage",
				"status":      "10",
			},
			{
				"id":          "2",
				"time":        "2",
				"alarmId":     35,
				"description": "Network Down",
				"status":      "20",
			}
		]
	});
}

function fetchGroupDevicesMock() {
	return Promise.resolve({
    "data": {
      "metadata": {
        "page": {
          "totalResults": 2,
          "page":        0,
          "limit":       10
        },
        "sort": [
          {
            "prop": "date",
            "order":  "DESC"
          }
        ]
      },
      "data":     [
        {
          "id":           "1",
          "deviceId":     "I-1569038",
          "swVersion":    "1.0.3",
          "swUpdateDate": "12-Mar-2020",
          "lumxVersion":  "2.0.2",
          "lumxUpdateDate": "12-Mar-2020"
        },
        {
          "id":           "2",
          "deviceId":     "I-8976459",
          "swVersion":    "1.0.1",
          "swUpdateDate": "10-Feb-2020",
          "lumxVersion":  "2.0.2",
          "lumxUpdateDate": "10-Feb-2020"
        },
      ]
    }
	});
}

export const publishedVersions = {
	"USA 7.2": {
		swVersion: "1.0.3",
		cloudSdkVersion: '2.0.2'
	},
	"USA 7.1": {
		swVersion: "1.0.3",
		cloudSdkVersion: '2.0.2'
	},
	"China 7.2": {
		swVersion: "1.0.3",
		cloudSdkVersion: '2.0.2'
	},
	"China 7.1": {
		swVersion: "2.1.2",
		cloudSdkVersion: '2.0.2'
	},
	"Europe 7.1": {
		swVersion: "2.1.2",
		cloudSdkVersion: '2.0.2'
	},
	"USA Illumisite": {
		swVersion: "1.0.3",
		cloudSdkVersion: '2.0.2'
	},
	"China Illumisite": {
		swVersion: "1.0.3",
		cloudSdkVersion: '2.0.2'
	},
}

const fetchGroupsMock = () => new Promise(
	(resolve, reject) => {
		setTimeout(() => resolve({
			"data": {
				"metadata": { "page": { "totalResults": 6, "page": 0, "limit": 10 }, "sort": [{ "prop": "creationTime", "order": "DESC" }] },
				"data":   [
					{
						id:          1,
						name:        'USA 7.2',
						device: {
							type: '7.2',
							typeId: '1ee2f8ac-8fab-4450-a1f1-ed253ea04454',
							family: 'SuperDimension V7',
							familyId: '460ed28e-a889-416e-b8b7-2aa378843e57',
							family1: 'ILS',
							familyId1: '2cf54988-38f6-465d-bdcb-a3f513cc825f'
						},
						locations: [
								{
									region: 'Americas',
									countryId: '19c97c7e-a89e-4740-bbb5-823ee8a05ef8',
									regionId: 'dbf7ce70-99b5-4cdc-bb26-0b4d45a06423',
									country:    'USA',
								}
						],
						swVersion:   publishedVersions["USA 7.2"].swVersion,
						lumxVersion: publishedVersions["USA 7.2"].cloudSdkVersion
					},
					{
						id:          10,
						name:        'China 7.2',
						device: {
							type: '7.2',
							typeId: '1ee2f8ac-8fab-4450-a1f1-ed253ea04454',
							family: 'SuperDimension V7',
							familyId: '460ed28e-a889-416e-b8b7-2aa378843e57',
							family1: 'ILS',
							familyId1: '2cf54988-38f6-465d-bdcb-a3f513cc825f'
						},
						locations: [
								{
									region: 'APAC',
									countryId: '485142b6-7f7f-4a7a-99fa-153de28d96f8',
									regionId: '39959c48-d737-4d8b-aeae-82ea1af34f3d',
									country:    'China',
								}
						],
						swVersion:   publishedVersions["China 7.2"].swVersion,
						lumxVersion: publishedVersions["China 7.2"].cloudSdkVersion
					},
					{
						id:          2,
						name:        'Europe 7.1',
						device: {
							type: '7.1',
							typeId: '16e1df66-f0fe-459c-8ce4-0f1fc35017fc',
							family: 'SuperDimension V7',
							familyId: '460ed28e-a889-416e-b8b7-2aa378843e57',
							family1: 'ILS',
							familyId1: '2cf54988-38f6-465d-bdcb-a3f513cc825f'
						},
						locations: [
							{
								region: 'EMEA',
								regionId: '78bda11f-393a-4330-9887-12ba43b3621c',
							}
						],
						swVersion:   publishedVersions["Europe 7.1"].swVersion,
						lumxVersion: publishedVersions["Europe 7.1"].cloudSdkVersion
					},
					{
						id:          20,
						name:        'China 7.1',
						device: {
							type: '7.1',
							typeId: '16e1df66-f0fe-459c-8ce4-0f1fc35017fc',
							family: 'SuperDimension V7',
							familyId: '460ed28e-a889-416e-b8b7-2aa378843e57',
							family1: 'ILS',
							familyId1: '2cf54988-38f6-465d-bdcb-a3f513cc825f'
						},
						locations: [
							{
								region: 'APAC',
								countryId: '485142b6-7f7f-4a7a-99fa-153de28d96f8',
								regionId: '39959c48-d737-4d8b-aeae-82ea1af34f3d',
								country:    'China',
							}
						],
						swVersion:   publishedVersions["China 7.1"].swVersion,
						lumxVersion: publishedVersions["China 7.1"].cloudSdkVersion
					},
					{
						id:          3,
						name:        'USA Illumisite',
						device: {
							type: '1.0',
							typeId: '243344de-41a4-4b27-b204-3059f335683c',
							family: 'Illumisite',
							familyId: '00a4ed61-7093-4808-9d16-42971a65da67',
							family1: 'ILS',
							familyId1: '2cf54988-38f6-465d-bdcb-a3f513cc825f'
						},
						locations: [
								{
									region: 'Americas',
									countryId: '19c97c7e-a89e-4740-bbb5-823ee8a05ef8',
									regionId: 'dbf7ce70-99b5-4cdc-bb26-0b4d45a06423',
									country:    'USA',
								}
						],
						swVersion:   publishedVersions["USA Illumisite"].swVersion,
						lumxVersion: publishedVersions["USA Illumisite"].cloudSdkVersion
					},
					{
						id:          30,
						name:        'China Illumisite',
						device: {
							type: '1.0',
							typeId: '243344de-41a4-4b27-b204-3059f335683c',
							family: 'Illumisite',
							familyId: '00a4ed61-7093-4808-9d16-42971a65da67',
							family1: 'ILS',
							familyId1: '2cf54988-38f6-465d-bdcb-a3f513cc825f'
						},
						locations: [
							{
								region: 'APAC',
								countryId: '485142b6-7f7f-4a7a-99fa-153de28d96f8',
								regionId: '39959c48-d737-4d8b-aeae-82ea1af34f3d',
								country:    'China',
							}
						],
						swVersion:   publishedVersions["China Illumisite"].swVersion,
						lumxVersion: publishedVersions["China Illumisite"].cloudSdkVersion
					},
				]
			}
		}), 1000);
	}
);

export const swVersionsData = {
	"data": {
	"metadata":   { "page": { "totalResults": 4, "page": 0, "limit": 10 }, "sort": [{ "prop": "creationTime", "order": "DESC" }] },
		"data": [
			{
				id:         "1.0.3",
				type:       'SuperDimension V7/7.2',
				version:    '1.0.3',
				uploadDate: '1/1/2020',
				uploadedBy: 'Maor Last',
				status:     1,
			},
			{
				id:         "2.1.2",
				type:       'SuperDimension V7/7.1',
				version:    '2.1.2',
				uploadDate: '1/1/2020',
				uploadedBy: 'Maor Last',
				status:     1,
			},
			{
				id:         "1.0.2",
				type:       'Illumisite/1.0',
				version:    '1.0.3',
				uploadDate: '3/1/2020',
				uploadedBy: 'Maor Last',
				status:     1,
			},
			{
				id:         "--4321.0.4343244334232",
				type:       'Puritan Bennett™ 840 Ventilator',
				version:    '1.0.3',
				uploadDate: '2/4/2020',
				uploadedBy: 'Ran Lev',
				status:     0,
			}
		]
	}
}
const fetchSwVersionsMock = () => new Promise(
	(resolve, reject) => {
		setTimeout(() => resolve(
			swVersionsData
		), 1000);
	}
);

const fetchLumenisXVersionsMock = () => Promise.resolve({
	"data": {
		"metadata":    { "page": { "totalResults": 2, "page": 0, "limit": 10 }, "sort": [{ "prop": "creationTime", "order": "DESC" }] },
		"data": [
			{
				id: "2.0.2",
				type: 'Microcap™ Handheld',
				softVersion: '2.0.2',
				uploadDate: '1/4/2020',
				uploadedBy: 'Ido Weitzman',
				status: 1,
			},
			{
				id: "2.0.3",
				softVersion: '2.0.2',
				type: 'Puritan Bennett™ 840 Ventilator',
				uploadDate: '31/3/2020',
				uploadedBy: 'Ran Lev',
				status: 0,
			}
		]
	}
});

const fetchMockDevices = () => Promise.resolve(MockResponses.fetchDevicesResponse);

const fetchAssignedDevicesMock = () => Promise.resolve(MockResponses.fetchAssignedDevicesResponse);

const createGroupMock = (data) => new Promise(
	(resolve, reject) => {
		setTimeout(() => resolve(data), 1000);
	}
);

const publishVersionToGroupMock = () => Promise.resolve({
	"data": {
	}
});

const fetchGroupVersionsMock = (deviceType) => Promise.resolve({
	"data": {
		"softwareVersions": [
			...swVersionsData.data.data.map(swVersion => ({
				...swVersion,
				value: swVersion.version
			}))
		].filter(version => version.type === deviceType),
		"lumenisXVersions": [
      {
				id: "2.0.2",
				value: '2.0.2',
			},
		],
	}
});

const fetchDeviceTypeHirarchyMock = () => (
	{
		"deviceTypeItems": [
		  {
			"id": "2cf54988-38f6-465d-bdcb-a3f513cc825f",
			"label": "ILS",
			"children": [
			  {
				"id": "460ed28e-a889-416e-b8b7-2aa378843e57",
				"label": "SuperDimension V7",
				"children": [
				  {
					"id": "16e1df66-f0fe-459c-8ce4-0f1fc35017fc",
					"label": "7.1",
					"children": null
				  },
				  {
					"id": "1ee2f8ac-8fab-4450-a1f1-ed253ea04454",
					"label": "7.2",
					"children": null
				  }
				]
			  },
			  {
				"id": "00a4ed61-7093-4808-9d16-42971a65da67",
				"label": "Illumisite",
				"children": [
				  {
					"id": "243344de-41a4-4b27-b204-3059f335683c",
					"label": "1.0",
					"children": null
				  }
				]
			  }
			]
		  }
		]
	  }
)

const fetchDeviceTypesMock = () => (
	{
		"metaData": {
		  "pagination": {
			"totalResult": 6,
			"page": 0,
			"limit": null
		  },
		  "sort": null
		},
		"deviceTypes": [
		  {
			"id": "16e1df66-f0fe-459c-8ce4-0f1fc35017fc",
			"productFamily1Id": "2cf54988-38f6-465d-bdcb-a3f513cc825f",
			"productFamily1": "ILS",
			"materialFamilyId": "460ed28e-a889-416e-b8b7-2aa378843e57",
			"materialFamily": "SuperDimension V7",
			"type": "7.1",
			"creationTime": "2020-03-15T17:35:26",
			"lastModifiedTime": "2020-03-15T17:35:26"
		  },
		  {
			"id": "1ee2f8ac-8fab-4450-a1f1-ed253ea04454",
			"productFamily1Id": "2cf54988-38f6-465d-bdcb-a3f513cc825f",
			"productFamily1": "ILS",
			"materialFamilyId": "460ed28e-a889-416e-b8b7-2aa378843e57",
			"materialFamily": "SuperDimension V7",
			"type": "7.2",
			"creationTime": "2020-03-15T17:35:26",
			"lastModifiedTime": "2020-03-15T17:35:26"
		  },
		  {
			"id": "21581de3-08c4-4a16-96f7-4e7ded96123d",
			"productFamily1Id": "2cf54988-38f6-465d-bdcb-a3f513cc825f",
			"productFamily1": "ILS",
			"materialFamilyId": "460ed28e-a889-416e-b8b7-2aa378843e57",
			"materialFamily": "SuperDimension V7",
			"type": "Microcap™ Handheld",
			"creationTime": "2020-03-15T17:35:26",
			"lastModifiedTime": "2020-03-15T17:35:26"
		  },
		  {
			"id": "243344de-41a4-4b27-b204-3059f335683c",
			"productFamily1Id": "2cf54988-38f6-465d-bdcb-a3f513cc825f",
			"productFamily1": "ILS",
			"materialFamilyId": "00a4ed61-7093-4808-9d16-42971a65da67",
			"materialFamily": "Illumisite",
			"type": "1.0",
			"creationTime": "2020-03-15T17:35:26",
			"lastModifiedTime": "2020-03-15T17:35:26"
		  },
		  {
			"id": "51aae34f-3660-4844-adeb-a06689effa1a",
			"productFamily1Id": "c5faa87a-30f6-4fb9-8359-8811ed75ee3c",
			"productFamily1": "RESPIRATORY",
			"materialFamilyId": "00a4ed61-7093-4808-9d16-42971a65da67",
			"materialFamily": "MECHANICAL VENTILATION",
			"type": "Puritan Bennett™ 840",
			"creationTime": "2020-03-15T17:35:26",
			"lastModifiedTime": "2020-03-15T17:35:26"
		  },
		  {
			"id": "6a3f7b15-fa03-454c-83f7-c5103aac9e97",
			"productFamily1Id": "c5faa87a-30f6-4fb9-8359-8811ed75ee3c",
			"productFamily1": "RESPIRATORY",
			"materialFamilyId": "00a4ed61-7093-4808-9d16-42971a65da67",
			"materialFamily": "MECHANICAL VENTILATION",
			"type": "Newport™ e360",
			"creationTime": "2020-03-15T17:35:26",
			"lastModifiedTime": "2020-03-15T17:35:26"
		  },
		  {
			"id": "243344de-41a4-4b27-b204-3059f335683121",
			"materialFamilyId": "00a4ed61-7093-4808-9d16-42971a65da6121",
			"materialFamily": "INTERVENTIONAL LUNG SOLUTIONS",
			"productFamily1Id": "c5faa87a-30f6-4fb9-8359-8811ed75ee3121",
			"productFamily1": "Lung Health",
			"type": "superDimension Navigation System",
			"creationTime": "2020-03-15T17:35:26",
			"lastModifiedTime": "2020-03-15T17:35:26"
		  }
		]
	  }
)

const updateMockAssignedDevice = ({ groupId, data }) => {
	MockResponses.fetchAssignedDevicesResponse.assignedDevices = data.devicesIds.map(deviceId => ({ "deviceId": deviceId }));
	return Promise.resolve({data: null});
}

// use can use https://www.mocky.io/ to simulate respose and delay the response
const callMockAPI = (mockUrl) => {
  const resource = {
		uri:         mockUrl,
		method: 'POST',
  };
  
  return ApiService.sendRequest({ resource })  
}

/**
|--------------------------------------------------
| The server service - expose interface to the API.
| Every method returns a promise.
|--------------------------------------------------
 */
const BackendService = {
	fetchUsers:                (params) => ApiService.sendRequest({ resource: Resources.fetchUsers, params: { includeGroups: true, ...params } }),
	addUser:                   (data) => ApiService.sendRequest({ resource: Resources.addUser, data }),
	fetchUserGroups:           () => ApiService.sendRequest({ resource: Resources.fetchUserGroups }),
	updateUser:                ({ userId, data }) => ApiService.sendRequest({ resource: Resources.updateUser, additionalPath: userId, data }),
  // fetchDeviceTypes:          () => ApiService.sendRequest({ resource: Resources.fetchDeviceTypes }),
  	fetchDeviceTypes:  (params) => fetchDeviceTypesMock(),
	fetchDevices:              (params) => ApiService.sendRequest({ resource: Resources.getDevices, params }),
	fetchMockDevices:		   () => fetchMockDevices(),
	addDevice:                 (data) => ApiService.sendRequest({ resource: Resources.addDevice, data }),
	fetchCountries:            () => ApiService.sendRequest({ resource: Resources.getCountries }),
	fetchPotentialManagers:    (userGroupId) => ApiService.sendRequest({ resource: Resources.fetchManagers, params: { userGroupId } }),
	fetchUser:                 (userId) => ApiService.sendRequest({ resource: Resources.fetchUser, additionalPath: userId }),
	fetchAssignedUsers:        ({ deviceSerialNumber, deviceType }) => ApiService.sendRequest({ resource: Resources.fetchAssignedUsers, additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}` }),
	updateAssignedUsers:       ({ deviceSerialNumber, deviceType, data }) => ApiService.sendRequest({ resource: Resources.updateAssignedUsers, additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}`, data }),
	fetchCustomerDetails:      ({ deviceSerialNumber, deviceType }) => ApiService.sendRequest({ resource: Resources.fetchCustomerDetails, additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}` }),
	updateCustomerDetails:     ({ deviceSerialNumber, deviceType, data }) => ApiService.sendRequest({ resource: Resources.updateCustomerDetails, additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}`, data }),
	fetchDeviceProperties:     ({ deviceSerialNumber, deviceType }) => ApiService.sendRequest({ resource: Resources.getDeviceProperties, additionalPath: `${deviceType}/deviceSerialNumbers/${deviceSerialNumber}` }),
	resetPasswordForUser:      (userId) => ApiService.sendRequest({ resource: Resources.resetPassword, additionalPath: `${userId}/resetPassword` }),
	disableDevice:             (data) => ApiService.sendRequest({ resource: Resources.disableDevice, data }),
  fetchLocationsHierarchy:   () => ApiService.sendRequest({ resource: Resources.getLocationsHierarchy }),
  // fetchDeviceTypesHierarchy: () => ApiService.sendRequest({ resource: Resources.getDeviceTypesHierarchy }),
  fetchDeviceTypesHierarchy:  (params) => fetchDeviceTypeHirarchyMock(),
	fetchAlarms:               (data) => fetchAlarmsMock(),
	fetchGroups:               (params) => fetchGroupsMock(params),//ApiService.sendRequest({ resource: Resources.fetchGroups, params}),
	createGroup:               (data) => Promise.resolve({}),
	updateGroup:               () => Promise.resolve({}), //({ groupId, data }) => ApiService.sendRequest({ resource: Resources.createGroup, data }),
	fetchSwVersions:           (params) => fetchSwVersionsMock(),//ApiService.sendRequest({ resource: Resources.fetchGroups, params}),
	fetchLumenisXVersions:     (params) => fetchLumenisXVersionsMock(),
	createLumenisXVersion:     (params) => (Promise.resolve({data: null})),
	createSwVersion:		   (params) => (Promise.resolve({data: null})),
	fetchAssignedDevices:      () => fetchAssignedDevicesMock(),
	updateAssignedDevices:     			  ({ groupId, data }) => updateMockAssignedDevice({ groupId, data }),
	updateAssignedDevicesWithAllMode:     ({ groupId, excluded }) => Promise.resolve({}),
	fetchGroupVersions:        ({groupId, deviceType}) => fetchGroupVersionsMock(deviceType),
	publishVersionToGroup:     (groupId) => publishVersionToGroupMock(),
	fetchGroupDevices:         (groupId) => fetchGroupDevicesMock()
};

export default BackendService;
