import React from 'react';
import { Page, FormContainer, MainTitle } from './index'
import Logo from 'components/Logo';



const AuthPageBase = (props) => {
	
	const { form } = props;
	
	return (
		<Page>
			<FormContainer direction="column" centered>
				<MainTitle level={2}>Welcome to bio-T</MainTitle>
				<Logo />
				
				{form}
			
			</FormContainer>
		</Page>
	);
};


export default AuthPageBase;