import React from 'react';
import PropTypes from 'prop-types';
import FormFactory from 'components/FormFactory';
import { Col, Row, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { withErrorBoundary } from 'components/ErrorBoundary';
import CountrySelector from 'containers/CountrySelector';
import DeviceTypeSelector from 'containers/DeviceTypeSelector';
import EmailField from 'components/FormFactory/fields/EmailField';
import FormField from 'components/FormFactory/FormField';
import SelectField from 'components/FormFactory/fields/SelectField';
import TextAreaField from 'components/FormFactory/fields/TextAreaField';
import TextField from 'components/FormFactory/fields/TextField';

import messages from './messages';



const { Option } = Select;
const Form = FormFactory.create({ name: 'creatDeviceForm', onFieldsChange: (props, changedFields, allFields) => {console.log(changedFields)} });

class DeviceForm extends React.Component {
	static propTypes = {
		device: PropTypes.object
	};


	state = {
		states: undefined
	};

	onSubmit = (deviceValues) => {
		const { device } = this.props;
		if (device) {
			const {deviceType: {type}, deviceSerialNumber} = device;
			this.props.updateCustomerDetails({deviceSerialNumber, deviceType: type, deviceValues} );
		} else {
			this.props.createDevice(deviceValues);
		}
	};

	onCancel = (form) => {
		this.props.hideModal();
	};

	render() {
		const { isCreateUpdateLoading, device, editBlocked } = this.props;

		const isEditMode = !!device;

		const submitButtonText = isEditMode ? 'Update Customer' : 'Create Device';
		const showStateSelector = !_.isEmpty(this.state.states);

		return (
			<Form
				submitButtonText={submitButtonText}
				hideSubmitButton={editBlocked}
				onSubmit={this.onSubmit}
				isLoading={isCreateUpdateLoading}
				hideCancelButton={false}
				onCancel={this.onCancel}
			>
				{
					form => {
						const onCountryChange = (value, [regionObject, countryObject]) => {
							const states = _.get(countryObject, 'states');
							this.setState({ states });
							form.setFieldsValue({ state: undefined });
						};

						return (
							<>
								<Row gutter={24}>
									<Col span={9}>
										<TextField name="deviceSerialNumber"
										           label={<FormattedMessage {...messages.deviceSerialNumber} />}
										           required={!isEditMode}
										           disabled={isEditMode}
										           maxLength={36}
										           initialValue={_.get(device, 'deviceSerialNumber')}
										/>
									</Col>
									<Col span={15}>
										<FormField name="deviceType"
										           label={<FormattedMessage {...messages.deviceType} />}
										           required={!isEditMode}
										           disabled={isEditMode}
										           initialValue={_.get(device, 'deviceType')}
										>
											<DeviceTypeSelector />
										</FormField>
									</Col>
								</Row>

								<h4>Customer:</h4>
								<Row gutter={24}>
									<Col span={9}>
										<TextField name="firstName"
										           label={<FormattedMessage {...messages.firstName} />}
										           maxLength={32}
										           disabled={editBlocked}
										           initialValue={_.get(device, 'customerDetails.firstName')}
										/>

										<TextField name="lastName"
										           label={<FormattedMessage {...messages.lastName} />}
										           maxLength={32}
										           disabled={editBlocked}
										           initialValue={_.get(device, 'customerDetails.lastName')}
										/>

										<EmailField name="email"
										            label={<FormattedMessage {...messages.email} />}
										            maxLength={64}
										            disabled={editBlocked}
										            initialValue={_.get(device, 'customerDetails.email')}
										/>

										<TextField name="phoneNumber"
										           label={<FormattedMessage {...messages.phoneNumber} />}
										           maxLength={32}
										           disabled={editBlocked}
										           initialValue={_.get(device, 'customerDetails.phoneNumber')}
										/>

										<TextAreaField name="comments"
										               label={<FormattedMessage {...messages.comments} />}
										               autoSize={{ minRows: 6, maxRows: 6 }}
										               maxLength={256}
										               disabled={editBlocked}
										               initialValue={_.get(device, 'customerDetails.comments')}
										/>
									</Col>
									<Col span={15}>
										<TextField name="street"
										           label={<FormattedMessage {...messages.street} />}
										           maxLength={100}
										           disabled={editBlocked}
										           initialValue={_.get(device, 'customerDetails.address.street')}
										/>

										<TextField name="streetNumber"
										           label={<FormattedMessage {...messages.streetNumber} />}
										           maxLength={10}
										           disabled={editBlocked}
										           initialValue={_.get(device, 'customerDetails.address.streetNumber')}
										/>

										<TextField name="city"
										           label={<FormattedMessage {...messages.city} />}
										           maxLength={100}
										           disabled={editBlocked}
										           initialValue={_.get(device, 'customerDetails.address.city')}
										/>

										<TextField name="zipCode"
										           label={<FormattedMessage {...messages.zipCode} />}
										           maxLength={12}
										           disabled={editBlocked}
										           initialValue={_.get(device, 'customerDetails.address.zipCode')}
										/>

										<FormField name="country"
										           label={<FormattedMessage {...messages.country} />}
										           onChange={onCountryChange}
										           disabled={editBlocked}
										           initialValue={[
										           	_.get(device, 'customerDetails.address.region'),
										            _.get(device, 'customerDetails.address.country'),
										           ]}
										>
											<CountrySelector />
										</FormField>

										{
											showStateSelector &&
											<SelectField name="state"
											             label={<FormattedMessage {...messages.state} />}
											             disabled={editBlocked}
											             initialValue={_.get(device, 'customerDetails.address.state')}
											>
												{
													this.state.states.map((state, i) =>
														<Option key={i} value={state}>{state}</Option>)
												}
											</SelectField>
										}

									</Col>
								</Row>
							</>
						)
					}
				}


			</Form>
		)
	}
}

export default withErrorBoundary(DeviceForm);