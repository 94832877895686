import { call, put, takeLatest } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'containers/App/modules/toasts/toasts.actions';
import UserManagement from "softimize-client-user-management";
import { forgotPasswordActions } from 'pages/ForgotPasswordPage/modules/forgotPassword.slice';
import { api } from 'containers/App/modules/async.saga';


export function* forgotPassword(action) {

	yield call(api, {
		apiFn:   [UserManagement, UserManagement.forgotPassword],
		params:  action.payload,
		actions: [
			forgotPasswordActions.forgotPasswordPending,
			forgotPasswordActions.forgotPasswordSuccess,
			forgotPasswordActions.forgotPasswordFailure,
		]
	});
}

function* forgotPasswordSuccess(action) {
	yield put(showSuccessToast({ title: 'Email sent', description: `An email with a password reset link has been sent. Please follow the instructions in the email.` }));
}

function* forgotPasswordFailure(action) {
	yield put(showErrorToast({ title: 'Unable to send email' }));
}

export default function* watchForgotPasswordSaga() {
	yield takeLatest(forgotPasswordActions.forgotPassword, forgotPassword);
	yield takeLatest(forgotPasswordActions.forgotPasswordSuccess, forgotPasswordSuccess);
	yield takeLatest(forgotPasswordActions.forgotPasswordFailure, forgotPasswordFailure);
}