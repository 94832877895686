import React from 'react';
import EntityGridToolbar from 'containers/Grid/components/EntityGridToolbar';
import _ from 'lodash';
import { MODAL_TYPES } from 'containers/ModalRoot/components/constants';
import { FormattedMessage } from 'react-intl';
import { PERMISSIONS } from 'services/PermissionsService';

import FormField from 'components/FormFactory/FormField';
import DeviceTypeSelector from 'containers/DeviceTypeSelector';

import messages from 'pages/Devices/components/messages';


const DevicesGridToolbar = (props) => {
	const {
		      setGridParams,
		      grid,
		      showAddNew = true,
		      showModal
	      } = props;

	const onFiltersChange = (filterValues) => {
		const {deviceTypeFilter,countryFilter } = filterValues;
		const filters = {
			['deviceInfo.deviceType']:           _.get(deviceTypeFilter, 'type'),
			['customerDetails.address.country']: _.isEmpty(countryFilter) ? null : countryFilter
		};

		setGridParams({
			...grid.params,
			pagination: { current: 1 }, //return to page 1 on new search
			filters
		});
	};

	const onSearchChange = (value) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1 }, //return to page 1 on new search
			search:     value
		});
	};

	const onResetFilters = (form) => {
		setGridParams({
			...grid.params,
			pagination: { current: 1 }, //return to page 1 on new search
			search:     '',
			filters:    {}
		});
	};

	const filterFormElements = (
		<>
			<FormField
				name="deviceTypeFilter"
				style={{ minWidth: 350 }}>
				<DeviceTypeSelector />
			</FormField>
		</>
	);

	return (
		<EntityGridToolbar
			onResetFilters={onResetFilters}
			onFiltersChange={onFiltersChange}
			onSearchChange={onSearchChange}
			filterFormElements={filterFormElements}
			showAddNew={showAddNew}
      addNewPermission={PERMISSIONS.UI_CREATE_DEVICE}
      showRefreshButton={true}
		/>
	);
};

DevicesGridToolbar.propTypes = {};

export default DevicesGridToolbar;