import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { DAEMON } from 'utils/constants';

import deviceEntitySaga from 'containers/App/modules/entities/devices/deviceEntity.saga';

import Component from './../components/Component';
import { actions as alarmsEntityActions, selectors as alarmsEntitySelectors } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';
import { actions as alarmsActions, selectors as alarmsSelectors} from './../modules/alarms.slice';
import reducer from './../modules/alarms.slice';
import { ACTION_STATUS } from 'containers/App/app.constants';

const withEntitySaga = injectSaga({
	key:  'alarmEntitySaga',
	saga: deviceEntitySaga,
	mode: DAEMON
});


const withReducer = injectReducer({
	key:     'alarms',
	reducer
});

const mapStateToProps = (state) => {
  const grid = alarmsSelectors.selectAlarmsGrid(state);
  const alarms = alarmsEntitySelectors.selectAlarms(state);

	return {
		isLoading: alarmsEntitySelectors.selectAlarmsStatus(state) === ACTION_STATUS.RUNNING,
		alarms: alarms ? alarms.data : [],
		grid
	}
};

const withConnect = connect(mapStateToProps, { ...alarmsEntityActions, ...alarmsActions });

export default compose(
	withEntitySaga,
	withReducer,
	withConnect
)(Component);
