import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography, Button } from 'antd';
import warningIcon from 'images/warning.webp';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';

const { Title } = Typography;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DisableDeviceTab = (props) => {

  const onDisableDevice = () => {
    props.disableDevice({
      deviceSerialNumber: props.device.deviceSerialNumber,
    });
  };

  const isBtnDisabled = props.device.status !== 10 || !hasPermission(PERMISSIONS.UI_DISABLE_DEVICE);
  return (
    <Container>
      <img src={warningIcon} alt="" width="37%" height="100%"/>
      <Title level={4} style={{marginTop: "20px"}}>Your are about to disable device "{props.device.deviceSerialNumber}"</Title>
      <Button 
        type="primary" style={{marginTop: "50px"}} 
        onClick={onDisableDevice}
        disabled={isBtnDisabled}
        loading={props.isDisableDeviceLoading}
      >
        Disable
      </Button>
    </Container>
	);
};

DisableDeviceTab.propTypes = {
  device: PropTypes.object,
  disableDevice: PropTypes.func.isRequired,
  isDisableDeviceLoading: PropTypes.bool.isRequired,
};

export default DisableDeviceTab;