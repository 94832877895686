//todo until we redefine softimize-client-user-management
import UserManagement from 'softimize-client-user-management';


export const hasPermission = UserManagement.hasPermission.bind(UserManagement);

export const PERMISSIONS = {
	//Users
	UI_VIEW_USERS:  'UI_VIEW_USERS',
	UI_CREATE_USER: 'UI_CREATE_USER',
	UI_UPDATE_USER: 'UI_UPDATE_USER',

	//Devices
	UI_VIEW_DEVICES:                    'UI_VIEW_DEVICES',
	UI_CREATE_DEVICE:                   'UI_CREATE_DEVICE',
	UI_UPDATE_DEVICE:                   'UI_UPDATE_DEVICE',
	UI_DISABLE_DEVICE:                  'UI_DISABLE_DEVICE',
	UI_EDIT_CUSTOMER_FOR_ACTIVE_DEVICE: 'UI_EDIT_CUSTOMER_FOR_ACTIVE_DEVICE',
	UI_ASSIGN_USERS_FOR_DEVICE:         'UI_ASSIGN_USERS_FOR_DEVICE',
	UI_EDIT_CUSTOMER_FOR_DEVICE:        'UI_EDIT_CUSTOMER_FOR_DEVICE',

	//LumenisX Versions
	// UI_CREATE_LUMENISX_VERSION:			'UI_CRAETE_LUMENISX_VERSION'
};