/* eslint import/no-mutable-exports: 0 */
/* eslint prefer-const: 0 */

let DEV_CONFIG = {
  API_URL: 'http://dev-alb-1338078987.us-east-1.elb.amazonaws.com',
  // API_URL: 'https://iot.petnovations.com',
  GOOGLE_ANALYTICS_TRACKER_ID: '',
};

export default DEV_CONFIG;

