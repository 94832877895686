import React, { useEffect } from 'react';
import EntityGrid from 'containers/Grid/components/EntityGrid';
import AlarmsGridToolbar from './AlarmsGridToolbar';

const AlarmGrid = (props) => {

  const columns = props.columns || [
    {
      key:       'time',
      title:     'Time',
      dataIndex: 'time',
      sorter:    true
    },
    {
      key:       'alarmId',
      title:     'Alarm Id',
      dataIndex: 'alarmId',
      sorter:    true
    },
    {
      key:       'description',
      title:     'Description',
      dataIndex: 'description',
    },
    {
      key:       'deviceId',
      title:     'Device Id',
      dataIndex: 'deviceId',
    },
    {
      key:       'deviceType',
      title:     'Device Type',
      dataIndex: 'deviceType',
      sorter:    true,
    },
  ];

  const toolbarComponent = (
    <AlarmsGridToolbar
      resetGridData={props.resetGridData}
      setGridParams={props.setGridParams}
      grid={props.grid}
      showAddNew={false}
    />
  );

  return (
    <EntityGrid
      toolbarComponent={toolbarComponent}
      grid={props.grid}
      fetchData={props.getAlarms}
      fetchDataPending={props.isLoading}
      data={props.alarms}
      columns={columns}
      resetGridData={props.resetGridData}
      isAutoRefresh={true}
      setGridParams={props.setGridParams}
    />
  );
  }
;

export default AlarmGrid;