import { connect } from "react-redux";
import { compose } from "redux";
import { modalActions } from "containers/ModalRoot/modules/modal.slice";
import { injectIntl } from "react-intl";
import { lumenisXVersionEntityActions } from "containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.slice";
import { lumenisXVersionsActions } from "../modules/lumenisXVersions.slice";
import LumenisXForm from "../components/LumenisXForm";

const mapStateToProps = (state, props) => ({});

const withConnect = connect(mapStateToProps, {
	...lumenisXVersionEntityActions,
	...lumenisXVersionsActions,
	...modalActions,
});

export default compose(withConnect, injectIntl)(LumenisXForm);
