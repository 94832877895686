import React from 'react';
import { MODAL_TYPES } from './constants';
import { Modal } from 'antd';

import UserFormContainer from 'pages/Users/containers/UserFormContainer';
import DeviceFormContainer from 'pages/Devices/containers/DeviceFormContainer';
import DevicePropertiesContainer from 'pages/Devices/containers/DevicePropertiesContainer';
import ErrorBoundary from 'components/ErrorBoundary';
import GroupFormContainer from 'pages/Groups/containers/GroupFormContainer';
import LumenisXFormContainer from 'pages/LumenisXVersions/containers/LumenisXFormContainer';
import SwVersionsFormContainer from 'pages/SwVersions/containers/SwVersionsFormContainer';
import AssignDevicesModalContainer from 'pages/Groups/containers/AssignDevicesModalContainer';
import GroupVersionsContainer from 'pages/Groups/containers/GroupVersionsContainer';
import GroupDevicesStatusContainer from 'pages/Groups/containers/GroupDevicesStatusContainer';


const MODAL_COMPONENTS = {
	[MODAL_TYPES.CREATE_USER_MODAL]:       UserFormContainer,
	[MODAL_TYPES.CREATE_DEVICE_MODAL]:     DeviceFormContainer,
	[MODAL_TYPES.DEVICE_PROPERTIES_MODAL]: DevicePropertiesContainer,
	[MODAL_TYPES.CREATE_GROUP_MODAL]:      GroupFormContainer,
	[MODAL_TYPES.UPLOAD_LUMENISX_VERISON_MODAL]: LumenisXFormContainer,
	[MODAL_TYPES.UPLOAD_SW_VERISON_MODAL]: SwVersionsFormContainer,
	[MODAL_TYPES.ASSIGN_DEVICES_TO_GROUP_MODAL]: AssignDevicesModalContainer,
	[MODAL_TYPES.UPDATE_GROUP_VERSIONS_MODAL]: GroupVersionsContainer,
	[MODAL_TYPES.GROUP_DEVICES_STATUS_MODAL]: GroupDevicesStatusContainer
};

const ModalRoot = ({ visible, modalType, modalProps, componentProps, hideModal }) => {
	const onCancel = () => hideModal();
	const Component = MODAL_COMPONENTS[modalType];
	return (
		<ErrorBoundary>
			<Modal onCancel={onCancel}
			       visible={visible}
			       maskClosable={false}
			       centered={true}
			       keyboard={false}
			       {...modalProps}>
				{
					Component ? <Component {...componentProps} /> : null
				}
			</Modal>
		</ErrorBoundary>
	);
};


export default ModalRoot;