import _ from 'lodash';
import { parseFetchAllRequest as parseAllRequest } from 'containers/App/modules/entities/users/users.parsers';


export const parseFetchAllRequest = payload =>
	parseAllRequest(payload);

const prepareDevice = device => {
	const { deviceInfo, customerDetails } = device;
	return {
		...deviceInfo,
		customerDetails,
		id: device.deviceInfo.manufacturerId,
	}
};

export const parseFetchAllSuccess = responseData => {
	const data = _.map(
		_.get(responseData, 'data', []),
		prepareDevice
	);
	return data;
};


export const parseFetchDevicePropertiesSuccess = responseData => prepareDevice(responseData);
export const parseCreateRequest = payload => {
	const {
		      deviceSerialNumber, deviceType,
		      city, country, state, street, streetNumber, zipCode,
		      comments, email, firstName, lastName, phoneNumber
	      } = payload;
	const countryName = _.get(country, '[1]');

	const deviceApiObject = {
		createLumDeviceRequest: {
			deviceSerialNumber,
			deviceType: deviceType.type
		},

		submitCustomerDetailsRequest: {
			address: {
				city, country: countryName, state, street, streetNumber, zipCode,
			},
			comments, email, firstName, lastName, phoneNumber
		}
	};
	return deviceApiObject;
};

export const parseCreateSuccess = payload => {
	const { data, requestParams } = payload;
	const { createLumDeviceRequest, submitCustomerDetailsRequest } = requestParams;
	const { manufacturerId: id } = data;
	return {
		id,
		...createLumDeviceRequest,
		...submitCustomerDetailsRequest
	};
};

export const parseFetchAssignedUsersSuccess = responseData => {
	return responseData.assignedUsers;
};

export const prepareUpdateAssignedUsersRequest = payload => {
	const { deviceType, deviceSerialNumber, explicitAssignedUserIds } = payload;
	return {
		payload: {
			deviceType,
			deviceSerialNumber,
			data: {
				userIds: explicitAssignedUserIds
			}
		}
	}
};

export const prepareUpdateCustomerDetailsRequest = payload => {
	const { city, country, state, street, streetNumber, zipCode, ...restOfDetails } = payload.deviceValues;
	const region = _.get(country, '[0]');
	const countryName = _.get(country, '[1]');
	return {
		payload: {
			...payload,
			data: {
				address: {
					city,
					country: countryName,
					region,
					state,
					street,
					streetNumber,
					zipCode
				},
				...restOfDetails
			}
		}
	}
};