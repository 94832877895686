import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormFactory from 'components/FormFactory';
import { Select } from 'antd';
import FormField from 'components/FormFactory/FormField';
import _ from 'lodash';
import TextField from 'components/FormFactory/fields/TextField';
import { withErrorBoundary } from 'components/ErrorBoundary';
import DeviceTypeTreeSelector from 'containers/DeviceTypeTreeSelector';
import LocationTreeSelector from 'containers/LocationTreeSelector';


const { Option } = Select;
const Form = FormFactory.create({ name: 'createGroupForm', }); //onFieldsChange: (props, changedFields, allFields) => console.log(changedFields)

const GroupForm = (props) => {
	const {
		      isGroupOperationLoading,
		      group,
		      groupId,
		      isGroupFetchLoading,
		      editBlocked,
		      clearEditedGroup,
		      updateGroup,
		      createGroup,
		      hideModal,
	      } = props;

	useEffect(() => {
		clearEditedGroup();
	}, []);

	const onSubmit = (groupValues) => {
		if (group) {
			updateGroup({ groupId: group.id, groupValues });
		} else {
			createGroup(groupValues);
		}
	};

	const onCancel = (form) => {
		hideModal();
	};



	const locationsDefaultValue = group && group.locations.map(location => {
		if(location.stateId) {
			return location.stateId;
		} else if (location.countryId) {
			return location.countryId;
		} else {
			return location.regionId;
		}
	})
	const isEditMode = !!group;
	const submitButtonText = isEditMode ? 'Update' : 'Create';
	return (
		<Form
			submitButtonText={submitButtonText}
			hideSubmitButton={editBlocked}
			onSubmit={onSubmit}
			onCancel={onCancel}
			hideCancelButton={false}
			isLoading={isGroupFetchLoading}
			isButtonLoading={isGroupOperationLoading}>

			<TextField
				name="name"
				label="Group Name"
				initialValue={[_.get(group, "name")]}
				required
			/>

			<FormField name="deviceType"
					   label="Device Type / Family"
					   initialValue={[_.get(group, "device.typeId")]}
			           required>
				<DeviceTypeTreeSelector
					treeDefaultExpandedKeys={[_.get(group, "device.typeId")]}
					multiSelect={false}
					nonSelectableTreeLevels={[0]}
					nonSelectableMessage="In Group, only Device Family or Device Type are assignable"
				/>
			</FormField>

			<FormField name="locations"
					   label="Locations"
					   initialValue={locationsDefaultValue}
			           required>
				<LocationTreeSelector
				 	treeDefaultExpandedKeys={locationsDefaultValue}
				 	withAll 
				/>
			</FormField>
		</Form>
	)
};

export default withErrorBoundary(GroupForm);