import { put, call, takeLatest } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'containers/App/modules/toasts/toasts.actions';
import BackendService from 'services/BackendService';
import { deviceEntityActions } from 'containers/App/modules/entities/devices/deviceEntity.slice';
import { api } from 'containers/App/modules/async.saga';
import { createEntityFailure, createEntitySuccess, updateEntityFailure, updateEntitySuccess } from 'containers/App/modules/entities/entities.utils';
import entitySchemas from 'containers/App/modules/api/schema';
import { parseFetchAllSuccess, parseFetchAssignedUsersSuccess, parseFetchDevicePropertiesSuccess } from 'containers/App/modules/entities/devices/devices.parsers';


function* fetchDevices(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchDevices,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema:                 [entitySchemas.device],
		params:                 action.payload,
		actions:                [
			deviceEntityActions.fetchDevicesPending,
			deviceEntityActions.fetchDevicesSuccess,
			deviceEntityActions.fetchDevicesFailure
		]
	});
}

function* fetchMockDevices(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchMockDevices,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema:                 [entitySchemas.device],
		params:                 action.payload,
		actions:                [
			deviceEntityActions.fetchDevicesPending,
			deviceEntityActions.fetchDevicesSuccess,
			deviceEntityActions.fetchDevicesFailure
		]
	});
}

function* createDevice(action) {
	const params = action.payload;
	yield call(api, {
		apiFn:   BackendService.addDevice,
		params,
		actions: [
			deviceEntityActions.createDevicePending,
			deviceEntityActions.createDeviceSuccess,
			deviceEntityActions.createDeviceFailure,
		]
	});
}


function* fetchAssignedUsers(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchAssignedUsers,
		parseSuccessResponseFn: parseFetchAssignedUsersSuccess,
		params:                 action.payload,
		actions:                [
			deviceEntityActions.fetchAssignedUsersPending,
			deviceEntityActions.fetchAssignedUsersSuccess,
			deviceEntityActions.fetchAssignedUsersFailure,
		]
	});
}

function* updateAssignedUsers(action) {
	yield call(api, {
		apiFn:   BackendService.updateAssignedUsers,
		params:  action.payload,
		actions: [
			deviceEntityActions.updateAssignedUsersPending,
			deviceEntityActions.updateAssignedUsersSuccess,
			deviceEntityActions.updateAssignedUsersFailure,
		]
	});
}

function* fetchCustomerDetails(action) {
	yield call(api, {
		apiFn:   BackendService.fetchCustomerDetails,
		schema:  entitySchemas.customerDetails,
		params:  action.payload,
		actions: [
			deviceEntityActions.fetchCustomerDetailsPending,
			deviceEntityActions.fetchCustomerDetailsSuccess,
			deviceEntityActions.fetchCustomerDetailsFailure,
		]
	});
}

function* updateCustomerDetails(action) {
	yield call(api, {
		apiFn:   BackendService.updateCustomerDetails,
		params:  action.payload,
		actions: [
			deviceEntityActions.updateCustomerDetailsPending,
			deviceEntityActions.updateCustomerDetailsSuccess,
			deviceEntityActions.updateCustomerDetailsFailure,
		]
	});
}

function* onUpdateCustomerDetailsSuccess(action) {
	yield updateEntitySuccess(action);
	//todo ugly, why? because API for fetch customer details to device type requires s/n and type
	// but I get the customerDetails ID on create success and have no service for that (supposed to update entity with ID also!)
	const { requestParams: { deviceSerialNumber, deviceType } } = action.payload;
	yield put(deviceEntityActions.fetchCustomerDetails({ deviceType, deviceSerialNumber }));
}

function* fetchDeviceProperties(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchDeviceProperties,
		parseSuccessResponseFn: parseFetchDevicePropertiesSuccess,
		schema:                 entitySchemas.device,
		params:                 action.payload,
		actions:                [
			deviceEntityActions.fetchCustomerDetailsPending,
			deviceEntityActions.fetchCustomerDetailsSuccess,
			deviceEntityActions.fetchCustomerDetailsFailure,
		]
	});
}

function* disableDevice(action) {
  yield call(api, {
		apiFn:                  BackendService.disableDevice,
		params:                 action.payload,
		actions:                [
			deviceEntityActions.disableDevicePending,
			deviceEntityActions.disableDeviceSuccess,
			deviceEntityActions.disableDeviceFailure,
		]
	});
}

function* disableDeviceSuccess(action) {

  yield put(showSuccessToast({
    title: 'Device disabled successfully',
  }))
}

function* disableDeviceFailure() {
  yield put(showErrorToast({
    title: 'Device disable failed',
  }))
}

export default function* watchDevicesSaga() {
	yield takeLatest(deviceEntityActions.fetchDevices, fetchDevices);
	yield takeLatest(deviceEntityActions.fetchMockDevices, fetchMockDevices);
	yield takeLatest(deviceEntityActions.fetchDeviceProperties, fetchDeviceProperties);
	yield takeLatest(deviceEntityActions.fetchAssignedUsers, fetchAssignedUsers);
	yield takeLatest(deviceEntityActions.fetchCustomerDetails, fetchCustomerDetails);

	yield takeLatest(deviceEntityActions.updateAssignedUsers, updateAssignedUsers);
	yield takeLatest(deviceEntityActions.updateAssignedUsersSuccess, updateEntitySuccess);
	yield takeLatest(deviceEntityActions.updateAssignedUsersFailure, updateEntityFailure);

	yield takeLatest(deviceEntityActions.updateCustomerDetails, updateCustomerDetails);
	yield takeLatest(deviceEntityActions.updateCustomerDetailsSuccess, onUpdateCustomerDetailsSuccess);
	yield takeLatest(deviceEntityActions.updateCustomerDetailsFailure, updateEntityFailure);

	yield takeLatest(deviceEntityActions.createDevice, createDevice);
	yield takeLatest(deviceEntityActions.createDeviceSuccess, createEntitySuccess);
  yield takeLatest(deviceEntityActions.createDeviceFailure, createEntityFailure);
  
	yield takeLatest(deviceEntityActions.disableDevice, disableDevice);
	yield takeLatest(deviceEntityActions.disableDeviceSuccess, disableDeviceSuccess);
	yield takeLatest(deviceEntityActions.disableDeviceFailure, disableDeviceFailure);
}