/*
 * App Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'sidemenu';

export default defineMessages({
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Users',
  },
  devices: {
    id: `${scope}.devices`,
    defaultMessage: 'Devices',
  },
  qlik: {
    id: `${scope}.qlik`,
    defaultMessage: 'Qlik',
  },
  alarms: {
    id: `${scope}.alarms`,
    defaultMessage: 'Alarms',
  },
  fota: {
    id: `${scope}.fota`,
    defaultMessage: 'Firmware Manager',
  },
  groups: {
    id: `${scope}.groups`,
    defaultMessage: 'Groups',
  },
  swVersions: {
    id: `${scope}.swVersions`,
    defaultMessage: 'SW Versions',
  },
  lumenisXVersions: {
    id: `${scope}.lumenisXVersions`,
    defaultMessage: 'LumenisX Versions',
  },
});
