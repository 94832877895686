import React from 'react';
import PropTypes from 'prop-types';
import FormFactory from 'components/FormFactory';
import { Button, Col, Popconfirm, Row, Select } from 'antd';
import FormField from 'components/FormFactory/FormField';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

import TextField from 'components/FormFactory/fields/TextField';
import EmailField from 'components/FormFactory/fields/EmailField';
import SelectField from 'components/FormFactory/fields/SelectField';
import RegionDeviceFormItem from 'pages/Users/containers/RegionDevicePairsContainer';
import UserGroupSelector from 'containers/UserGroupSelector';
import SwitchField from 'components/FormFactory/fields/SwitchField';
import { withErrorBoundary } from 'components/ErrorBoundary';

const { Option } = Select;
const Form = FormFactory.create({ name: 'createUserForm',}); //onFieldsChange: (props, changedFields, allFields) => console.log(changedFields)

class UserForm extends React.Component {
	static propTypes = {
		user: PropTypes.object
	};

	componentDidMount() {
		const { fetchUser, fetchPotentialManagers, user } = this.props;
		const curGroupId = _.get(this.props, 'user.group.id');
		if (curGroupId) {
			fetchPotentialManagers(curGroupId);
		}
	}

	//todo convert to functional component and useEffect() for this logic!
	componentDidUpdate(prevProps, prevState, snapshot) {
		const prevGroupId = _.get(prevProps, 'user.group.id');
		const curGroupId = _.get(this.props, 'user.group.id');

		if (prevGroupId !== curGroupId) {
			const { fetchUser, fetchPotentialManagers, user } = this.props;
			fetchPotentialManagers(curGroupId);
		}
	}

	componentWillUnmount() {
		this.props.clearEditedUser();
	}

	onSubmit = (userValues) => {
		const { user } = this.props;
		if (user) {
			this.props.updateUser({ userId: user.id, userValues });
		} else {
			this.props.createUser(userValues);
		}
	};

	onCancel = (form) => {
		this.props.hideModal();
	};

	resetPassword = () => {
		const { id } = this.props.user;
		this.props.resetPasswordForUser(id);
	};

	getManagerName = (manager) => {
		return `${manager.firstName} ${manager.lastName}`;
	};

	render() {
		const {
			      isUserOperationLoading,
			      isManagersLoading,
			      user,
			      userId,
			      managers,
			      isUserFetchLoading,
			      editBlocked,
			      resetPasswordPending
		      } = this.props;

		const isEditMode = !!user;
		// const isLocked = _.get(user, 'locked');
		const potentialManagers = _.filter(managers, manager => {
			return _.get(manager, 'id') !== userId
		});
		const hasManagers = !_.isEmpty(potentialManagers);

		let extraButtons;
		if (isEditMode) {
			extraButtons = (
				<>
					{/*<Popconfirm title="Sure to delete?" onConfirm={this.onDeleteUser}>
						<Button type="danger" loading={isDeleteLoading}>Delete User</Button>
					</Popconfirm>*/}

					<Button loading={resetPasswordPending}
					        onClick={this.resetPassword}>Reset Password</Button>

					{/*{
						isLocked ?
							<Button onClick={this.onEnableUser}>Enable User</Button> :
							<Button onClick={this.onDisableUser}>Disable User</Button>
					}*/}
				</>
			);
		}

		const submitButtonText = isEditMode ? 'Update' : 'Create';
		return (
			<Form
				submitButtonText={submitButtonText}
				hideSubmitButton={editBlocked}
				onSubmit={this.onSubmit}
				onCancel={this.onCancel}
				extraButtons={extraButtons}
				hideCancelButton={false}
				isLoading={isUserFetchLoading}
				isButtonLoading={isUserOperationLoading}>
				{
					form => {
						const onUserGroupChange = (userGroupId) => {
							form.setFieldsValue({
								manager: undefined
							});
							const { fetchPotentialManagers } = this.props;
							fetchPotentialManagers(userGroupId);
						};

						return (
							<Row gutter={24}>
								<Col span={7}>
									<TextField name="firstName"
									           label={<FormattedMessage {...messages.firstName} />}
									           required
									           maxLength={32}
									           initialValue={_.get(user, 'firstName')}
									           disabled={editBlocked}
									/>

									<TextField name="lastName"
									           label={<FormattedMessage {...messages.lastName} />}
									           required
									           maxLength={32}
									           initialValue={_.get(user, 'lastName')}
									           disabled={editBlocked}
									/>

									<EmailField name="email"
									            label={<FormattedMessage {...messages.email} />}
									            required
									            maxLength={64}
									            initialValue={_.get(user, 'email')}
									            disabled={editBlocked}
									/>

									<TextField name="phone"
									           required={true}
									           label={<FormattedMessage {...messages.phoneNumber} />}
									           maxLength={32}
									           initialValue={_.get(user, 'phone')}
									           disabled={editBlocked}
									/>

									{
										editBlocked ?
											<TextField name="group"
											           label={<FormattedMessage {...messages.userGroup} />}
											           initialValue={_.get(user, 'group.name')}
											           disabled={true}
											/> :
											<FormField name="group"
											           required
											           onChange={onUserGroupChange}
											           label={<FormattedMessage {...messages.userGroup} />}
											           initialValue={_.get(user, 'group.id')}
											>
												<UserGroupSelector />
											</FormField>
									}

									{
										editBlocked ?
											<TextField name="manager"
											           label={<FormattedMessage {...messages.manager} />}
											           initialValue={this.getManagerName(_.get(user, 'manager'))}
											           disabled={true}
											/> :
											<SelectField name="manager"
											             label={<FormattedMessage {...messages.manager} />}
											             required
											             initialValue={_.get(user, 'manager.id')}
											             disabled={!hasManagers || isManagersLoading}
											             loading={isManagersLoading}>
												{
													hasManagers && potentialManagers.map((manager, i) => {
														const managerName = this.getManagerName(manager);
														return <Select.Option key={i} value={manager.id}>{managerName}</Select.Option>;
													})
												}
											</SelectField>


									}

									{
										isEditMode &&
										<SwitchField name="locked"
										             label={<FormattedMessage {...messages.locked} />}
										             initialValue={_.get(user, 'locked')}
										             disabled={editBlocked}
										/>
									}
								</Col>

								<Col span={17}>

									<FormField name="devices"
									           label={<FormattedMessage {...messages.deviceType} />}
									           initialValue={_.get(user, 'devices')}
									           disabled={editBlocked}
									>
										<RegionDeviceFormItem />
									</FormField>

								</Col>
							</Row>
						);
					}
				}
			</Form>
		)
	}
}

export default withErrorBoundary(UserForm);