import React, { useEffect, useState } from 'react';
import { Icon, Menu } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';


import messages from 'containers/App/messages';
import Box from 'components/Box';
import { hasPermission, PERMISSIONS } from 'services/PermissionsService';

const {SubMenu} = Menu;

const SideMenu = () => {
	const [selectedItem, setSelectedItem] = useState(window.location.pathname.split('/')[1] || 'home')
	const onSelect = (e) => setSelectedItem(e.key);

	return (
		<Menu
			theme="dark"
			mode="inline"
			selectedKeys={[selectedItem]}
			onSelect={onSelect}
		>
			<Menu.Item key="home">
				<RouterLink to="/">
					<Box hAligned>
						<Icon type="home" />
						<FormattedMessage {...messages.home} />
					</Box>
				</RouterLink>
			</Menu.Item>
			{
				//todo-permissions: need to decouple permissions from UI! VerifyChildPermissions caused problems with antd here (class name undefined)
				hasPermission(PERMISSIONS.UI_VIEW_USERS) &&
				<Menu.Item key="users">
					<RouterLink to="/users">
						<Box hAligned>
							<Icon type="user" />
							<FormattedMessage {...messages.users} />
						</Box>
					</RouterLink>
				</Menu.Item>
			}
			{
				hasPermission(PERMISSIONS.UI_VIEW_DEVICES) &&
				<Menu.Item key="devices">
					<RouterLink to="/devices">
						<Box hAligned>
							<Icon type="cloud-server" />
							<FormattedMessage {...messages.devices} />
						</Box>
					</RouterLink>
				</Menu.Item>
			}
		   {/* <Menu.Item key="alarms">
			 <RouterLink to="/alarms">
			   <Box hAligned>
			     <Icon type="info-circle" />
			     <FormattedMessage {...messages.alarms} />
			   </Box>
			 </RouterLink>
			</Menu.Item> */}
		

			<SubMenu key="fota"
				title={
					<Box hAligned>
					<Icon type="fullscreen" />
					<FormattedMessage {...messages.fota} />
				</Box>}>

				<Menu.Item key="groups">
					<RouterLink to="/groups">
						<Icon type="gold" />
						<FormattedMessage {...messages.groups} />
					</RouterLink>
				</Menu.Item>

				<Menu.Item key="swVersions">
					<RouterLink to="/swVersions">
						<Icon type="number" />
						<FormattedMessage {...messages.swVersions} />
					</RouterLink>
				</Menu.Item>

				<Menu.Item key="cloudSDKVersions">
					<RouterLink to="/cloudSDKVersions">
						<Icon type="number" />
						Cloud SDK
					</RouterLink>
				</Menu.Item>
			</SubMenu>
		</Menu>
	)
};



export default SideMenu;