import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Descriptions, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { v4 as uuid } from 'uuid';
import DeviceStatus from 'pages/Devices/components/DeviceStatus';


const DevicePropertiesTab = (props) => {
	const { device } = props;

	const propsToShow = [
		'deviceType.type',
		'deviceSerialNumber',
		'status',
		'creationTime',
		'activatedByUserEmail',
		'iccid',
		'imei',
		'applicationVersion',
		'xplatformVersion',
		'eventsKeysDictionaryVersion',
		'systemStartByLocalClock',
		'systemStartByInternetClock',
		'timestampsOffset',
		'customMessage',
		'lastMessageTimestamp',
		'lastUpdatedTime',
	];

	const data = propsToShow && propsToShow.map((prop, i) => ({
			key:   uuid(), //for ant table
			prop,
			label: <FormattedMessage {...messages[prop]} />,
			value: _.get(device, prop)
		})
	);

	const renderByPropType = (text, record) => {
		if (record.prop === 'status') {
			return <DeviceStatus status={text} />;
		} else {
			return text;
		}
	};

	return (
		<Table
			dataSource={data}
			size="small"
			pagination={false}
		>

			<Table.Column
				key="label"
				title="Property"
				dataIndex="label"
			/>

			<Table.Column
				key="value"
				title="Value"
				dataIndex="value"
				render={renderByPropType}
			/>
		</Table>
		/*<Descriptions
			layout="vertical"
			size="small"
			bordered>

			{
				propsToShow && propsToShow.map((prop, i) => {
					return <Descriptions.Item
						span={3}
						key={i}
						label={<FormattedMessage {...messages[prop]} />}
					>
						{_.get(device, prop)}
					</Descriptions.Item>
				})
			}

		</Descriptions>*/
	);
};

DevicePropertiesTab.propTypes = {
	device: PropTypes.object
};

export default DevicePropertiesTab;