import { call, put, takeLatest } from 'redux-saga/effects';
import BackendService from 'services/BackendService';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';
import { api } from 'containers/App/modules/async.saga';
import { createEntityFailure, createEntitySuccess, errorToast, successToast, updateEntityFailure, updateEntitySuccess } from 'containers/App/modules/entities/entities.utils';
import { parseFetchAllSuccess, parseFetchByIdSuccess, parseFetchPotentialManagersSuccess } from 'containers/App/modules/entities/users/users.parsers';
import schemas from 'containers/App/modules/api/schema';


function* fetchUsers(action) {
	yield call(api, {
		apiFn:                  BackendService.fetchUsers,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema:                 [schemas.user],
		params:                 action.payload,
		actions:                [
			userEntityActions.fetchUsersPending,
			userEntityActions.fetchUsersSuccess,
			userEntityActions.fetchUsersFailure
		]
	});
}

function* fetchUser(action) {
	const userId = action.payload;
	yield call(api, {
		apiFn:                  BackendService.fetchUser,
		parseSuccessResponseFn: parseFetchByIdSuccess,
		schema:                 schemas.user,
		params:                 userId,
		actions:                [
			userEntityActions.fetchUserPending,
			userEntityActions.fetchUserSuccess,
			userEntityActions.fetchUserFailure
		]
	});
}

function* createUser(action) {
	const params = action.payload;
	yield call(api, {
		apiFn:   BackendService.addUser,
		params,
		actions: [
			userEntityActions.createUserPending,
			userEntityActions.createUserSuccess,
			userEntityActions.createUserFailure,
		]
	});
}

function* updateUser(action) {
	yield call(api, {
		apiFn:   BackendService.updateUser,
		params:  action.payload,
		actions: [
			userEntityActions.updateUserPending,
			userEntityActions.updateUserSuccess,
			userEntityActions.updateUserFailure
		]
	});
}

function* fetchPotentialManagers(action) {
	const userGroupId = action.payload;
	yield call(api, {
		apiFn:                  BackendService.fetchPotentialManagers,
		parseSuccessResponseFn: parseFetchPotentialManagersSuccess,
		schema:                 [schemas.user],
		params:                 userGroupId,
		actions:                [
			userEntityActions.fetchPotentialManagersPending,
			userEntityActions.fetchPotentialManagersSuccess,
			userEntityActions.fetchPotentialManagersFailure
		]
	});
}

function* fetchLoggedInUser(action) {
	const userId = action.payload;
	yield call(api, {
		apiFn:                  BackendService.fetchUser,
		parseSuccessResponseFn: parseFetchByIdSuccess,
		schema:                 schemas.user,
		params:                 userId,
		actions:                [
			userEntityActions.fetchLoggedInUserPending,
			userEntityActions.fetchLoggedInUserSuccess,
			userEntityActions.fetchLoggedInUserFailure
		]
	});
}

function* resetPasswordForUser(action) {
	const userId = action.payload;
	yield call(api, {
		apiFn:   BackendService.resetPasswordForUser,
		params:  userId,
		actions: [
			userEntityActions.resetPasswordForUserPending,
			userEntityActions.resetPasswordForUserSuccess,
			userEntityActions.resetPasswordForUserFailure
		]
	});
}

function* onUpdateUserSuccess(action) {
	//todo calling fetch of user id will get the updated state from server after success - instant feedback in UI but another API call.
	// to save that call - we could potentially update manually the redux store after success
	// this is hard with current architecture as the service returns only user ID for the updated user.
	yield put(userEntityActions.fetchUser(action.payload.id));
	yield updateEntitySuccess(action);
}

function* onResetPasswordForUserSuccess() {
	yield successToast('Reset Password Successful');
}

function* onResetPasswordForUserFailure(action) {
	yield errorToast(action, 'Reset Password Failed');
}

function* updatePreferences(action) {
  const preferences = action.payload;
  
  yield put(userEntityActions.updatePreferencesSuccess());
	// yield call(api, {
	// 	apiFn:   BackendService.resetPasswordForUser,
	// 	params:  userId,
	// 	actions: [
	// 		userEntityActions.resetPasswordForUserPending,
	// 		userEntityActions.resetPasswordForUserSuccess,
	// 		userEntityActions.resetPasswordForUserFailure
	// 	]
	// });
}

function* onUpdatePreferencesSuccess() {
	yield successToast('Preferences were updated successful');
}

function* onUpdatePreferencesFailure() {
	yield errorToast('Preferences update failure');
}

export default function* watchUsersSaga() {
	yield takeLatest(userEntityActions.fetchUsers, fetchUsers);
	yield takeLatest(userEntityActions.fetchUser, fetchUser);
	yield takeLatest(userEntityActions.fetchPotentialManagers, fetchPotentialManagers);
	yield takeLatest(userEntityActions.fetchLoggedInUser, fetchLoggedInUser);

	yield takeLatest(userEntityActions.resetPasswordForUser, resetPasswordForUser);
	yield takeLatest(userEntityActions.resetPasswordForUserSuccess, onResetPasswordForUserSuccess);
	yield takeLatest(userEntityActions.resetPasswordForUserFailure, onResetPasswordForUserFailure);

	yield takeLatest(userEntityActions.createUser, createUser);
	yield takeLatest(userEntityActions.createUserSuccess, createEntitySuccess);
	yield takeLatest(userEntityActions.createUserFailure, createEntityFailure);

	yield takeLatest(userEntityActions.updateUser, updateUser);
	yield takeLatest(userEntityActions.updateUserSuccess, onUpdateUserSuccess);
  yield takeLatest(userEntityActions.updateUserFailure, updateEntityFailure);
  
	yield takeLatest(userEntityActions.updatePreferences, updatePreferences);
	yield takeLatest(userEntityActions.updatePreferencesSuccess, onUpdatePreferencesSuccess);
  yield takeLatest(userEntityActions.updatePreferencesFailure, onUpdatePreferencesFailure);
}