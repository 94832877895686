import Loadable from './Loadable';
import {USER_ACTION_TYPES, USER_ACTION_CREATORS} from './modules/user.actions';
import reducer from './modules/users.slice';
import saga from 'containers/App/modules/entities/users/userEntity.saga'

export default {
	component: Loadable,
	actions: USER_ACTION_CREATORS,
	actionTypes: USER_ACTION_TYPES,
	saga,
	reducer
};