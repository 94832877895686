import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import {push} from 'connected-react-router';

import loginSaga from '../modules/login.saga';
import { createIsLoadingSelector } from 'containers/App/modules/async.selectors';

import LoginForm from '../components/LoginForm';
import injectReducer from 'utils/injectReducer';
import loginReducer, { loginActions } from 'pages/LoginPage/modules/login.slice';
import { selectLoginIsLoading } from 'pages/LoginPage/modules/login.selectors';

const mapStateToProps = (state, props) => {
	return {
		isLoading: selectLoginIsLoading(state),
	}
};

const withConnect = connect(mapStateToProps, {...loginActions, push});


export default compose(
	withConnect
)(LoginForm);

