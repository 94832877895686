const fetchDevicesResponse = {
    "data": {
        "metadata": {
        "page": {
            "totalResults": 4,
            "page": 0,
            "limit": 10
        },
        "sort": [
            {
                "prop": "creationTime",
                "order": "DESC"
            }
        ]
        },
        "data": [
        {
            "deviceInfo": {
            "manufacturerId": "981b1495-43f0-4d18-96fb-957b86edd4c4",
            "ownerId": null,
            "name": null,
            "hwVersion": null,
            "fwVersion": null,
            "type": 0,
            "status": 0,
            "reportedStatus": null,
            "creationTime": null,
            "lastUpdatedTime": null,
            "customProperties": [],
            "childrenIds": [],
            "isOnlineTimestamp": 0,
            "deviceType": "GA-1000000CN",
            "deviceSerialNumber": "1567891",
            "accessKey": null,
            "activatedByUserEmail": null,
            "iccid": null,
            "imei": null,
            "applicationVersion": null,
            "eventsKeysDictionaryVersion": null,
            "systemStartByLocalClock": null,
            "systemStartByInternetClock": null,
            "timestampsOffset": null,
            "customMessage": null,
            "commandsDictionaryVersion": null,
            "activationType": null,
            "applicationUpdateTimestamp": null,
            "xplatformUpdateTimestamp": null,
            "xplatformVersion": null,
            "online": false
            },
            "customerDetails": {
            "id": "b7dff906-6ee1-400b-a14f-d9d7e5912f01",
            "firstName": "Mayo Clinic",
            "lastName": "Dr John Fowler",
            "email": null,
            "phoneNumber": null,
            "address": {
                "country": "USA",
                "countryId": null,
                "region": "Americas",
                "regionId": null,
                "state": null,
                "stateId": null,
                "city": null,
                "street": null,
                "streetNumber": null,
                "zipCode": null
            },
            "comments": null
            }
        },
        {
            "deviceInfo": {
            "manufacturerId": "dbf7f27f-5a48-4dce-b777-5484f2105616",
            "ownerId": null,
            "name": null,
            "hwVersion": null,
            "fwVersion": null,
            "type": 0,
            "status": 0,
            "reportedStatus": null,
            "creationTime": null,
            "lastUpdatedTime": null,
            "customProperties": [],
            "childrenIds": [],
            "isOnlineTimestamp": 0,
            "deviceType": "GA-0000070DE",
            "deviceSerialNumber": "JOJO3",
            "accessKey": null,
            "activatedByUserEmail": null,
            "iccid": null,
            "imei": null,
            "applicationVersion": null,
            "eventsKeysDictionaryVersion": null,
            "systemStartByLocalClock": null,
            "systemStartByInternetClock": null,
            "timestampsOffset": null,
            "customMessage": null,
            "commandsDictionaryVersion": null,
            "activationType": null,
            "applicationUpdateTimestamp": null,
            "xplatformUpdateTimestamp": null,
            "xplatformVersion": null,
            "online": false
            },
            "customerDetails": {
            "id": "56b60ac6-9088-4bad-8ea0-bf38b9d93ce1",
            "firstName": null,
            "lastName": null,
            "email": null,
            "phoneNumber": null,
            "address": {
                "country": null,
                "countryId": null,
                "region": null,
                "regionId": null,
                "state": null,
                "stateId": null,
                "city": null,
                "street": null,
                "streetNumber": null,
                "zipCode": null
            },
            "comments": null
            }
        },
        {
            "deviceInfo": {
            "manufacturerId": "342fb640-9dd0-4ade-8f53-96a5831a0929",
            "ownerId": null,
            "name": null,
            "hwVersion": null,
            "fwVersion": null,
            "type": 0,
            "status": 0,
            "reportedStatus": null,
            "creationTime": null,
            "lastUpdatedTime": null,
            "customProperties": [],
            "childrenIds": [],
            "isOnlineTimestamp": 0,
            "deviceType": "COMMISSION-ALLEGRE",
            "deviceSerialNumber": "I-1567899",
            "accessKey": null,
            "activatedByUserEmail": null,
            "iccid": null,
            "imei": null,
            "applicationVersion": null,
            "eventsKeysDictionaryVersion": null,
            "systemStartByLocalClock": null,
            "systemStartByInternetClock": null,
            "timestampsOffset": null,
            "customMessage": null,
            "commandsDictionaryVersion": null,
            "activationType": null,
            "applicationUpdateTimestamp": null,
            "xplatformUpdateTimestamp": null,
            "xplatformVersion": null,
            "online": false
            },
            "customerDetails": {
            "id": "853ae068-5e19-460a-a22b-4bec58762bd6",
            "firstName": "Dr Patrick",
            "lastName": "Mayo Clinic",
            "email": null,
            "phoneNumber": null,
            "address": {
                "country": "USA",
                "countryId": null,
                "region": "Americas",
                "regionId": null,
                "state": null,
                "stateId": null,
                "city": null,
                "street": null,
                "streetNumber": null,
                "zipCode": null
            },
            "comments": null
            }
        },
        {
            "deviceInfo": {
            "manufacturerId": "2dac692b-b2c0-40ac-b60c-94395d97d648",
            "ownerId": null,
            "name": null,
            "hwVersion": null,
            "fwVersion": null,
            "type": 0,
            "status": 0,
            "reportedStatus": null,
            "creationTime": null,
            "lastUpdatedTime": null,
            "customProperties": [],
            "childrenIds": [],
            "isOnlineTimestamp": 0,
            "deviceType": "C00990-01",
            "deviceSerialNumber": "JOJO2",
            "accessKey": null,
            "activatedByUserEmail": null,
            "iccid": null,
            "imei": null,
            "applicationVersion": null,
            "eventsKeysDictionaryVersion": null,
            "systemStartByLocalClock": null,
            "systemStartByInternetClock": null,
            "timestampsOffset": null,
            "customMessage": null,
            "commandsDictionaryVersion": null,
            "activationType": null,
            "applicationUpdateTimestamp": null,
            "xplatformUpdateTimestamp": null,
            "xplatformVersion": null,
            "online": false
            },
            "customerDetails": {
            "id": "e0259d22-6053-48df-851f-4310f9b8eca5",
            "firstName": "First",
            "lastName": "Last",
            "email": "emai@l.com",
            "phoneNumber": "34567890",
            "address": {
                "country": "Burma",
                "countryId": null,
                "region": "APAC",
                "regionId": null,
                "state": null,
                "stateId": null,
                "city": "city",
                "street": "street",
                "streetNumber": "number",
                "zipCode": "564"
            },
            "comments": null
            }
        },
        {
            "deviceInfo": {
            "manufacturerId": "342fb640-9dd0-4ade-8f53-96a5831a0920",
            "ownerId": null,
            "name": null,
            "hwVersion": null,
            "fwVersion": null,
            "type": 0,
            "status": 0,
            "reportedStatus": null,
            "creationTime": null,
            "lastUpdatedTime": null,
            "customProperties": [],
            "childrenIds": [],
            "isOnlineTimestamp": 0,
            "deviceType": "COMMISSION-ALLEGRE",
            "deviceSerialNumber": "1567890",
            "accessKey": null,
            "activatedByUserEmail": null,
            "iccid": null,
            "imei": null,
            "applicationVersion": null,
            "eventsKeysDictionaryVersion": null,
            "systemStartByLocalClock": null,
            "systemStartByInternetClock": null,
            "timestampsOffset": null,
            "customMessage": null,
            "commandsDictionaryVersion": null,
            "activationType": null,
            "applicationUpdateTimestamp": null,
            "xplatformUpdateTimestamp": null,
            "xplatformVersion": null,
            "online": false
            },
            "customerDetails": {
                "id": "b7dff906-6ee1-400b-a14f-d9d7e5912f02",
                "firstName": "Mayo Clinic",
                "lastName": "Dr Smith",
                "email": null,
                "phoneNumber": null,
                "address": {
                    "country": "USA",
                    "countryId": null,
                    "region": "Americas",
                    "regionId": null,
                    "state": null,
                    "stateId": null,
                    "city": null,
                    "street": null,
                    "streetNumber": null,
                    "zipCode": null
                },
                "comments": null
            }
        },
        ]
    }
  };

const fetchAssignedDevicesResponse = {
    assignedDevices: [
        {
            deviceId: '981b1495-43f0-4d18-96fb-957b86edd4c4',
        },
        {
            deviceId: '342fb640-9dd0-4ade-8f53-96a5831a0920',
        }
    ]
};

export default { fetchDevicesResponse, fetchAssignedDevicesResponse };