import _ from 'lodash';
import { ALL_VALUE } from 'containers/App/app.constants';
import { schema, normalize, denormalize } from 'normalizr';


/*
const type = new schema.Entity('types');
const family = new schema.Entity('families', {
	children: [type]
});

const family1 = new schema.Entity('family1s', {
	children: [family]
});

const treeNode = new schema.Entity('nodes');
treeNode.define({
	children: [treeNode]
});
*/


export const getHierarchyData = treeArr => {
	let idToLabelMap = {
		[ALL_VALUE]: ALL_VALUE
	};

	const buildTree = (arr, labelPrefix = '', level = 0) => {
		if (_.isEmpty(arr))
			return arr;

		return _.sortBy(
			_.map(arr, node => {
				const { id, label } = node;
				const title = label;
				const key = id;
				const value = id;
				const fullPath = labelPrefix + label;
				idToLabelMap[id] = fullPath;

				return {
					id,
					level,
					key,
					title,
					fullPath,
					value,
					children: buildTree(node.children, fullPath + '/', level + 1)
				}
			}),
			'title');
	};

	const tree = buildTree(treeArr);

	// const normalizedData = normalize(tree, [treeNode]);
	// const nodesById = normalizedData.entities.nodes;
	// const node = denormalize("9486e678-f42d-462d-9425-e5069881fc54", treeNode, normalizedData.entities);
	// console.log(`node `, node);

	return { tree, idToLabelMap };
};


