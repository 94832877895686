import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import _ from 'lodash';

import {
	parseFetchAllSuccess,
	parseCreateRequest,
	parseCreateSuccess,
	parseFetchAllRequest,
	prepareUpdateCustomerDetailsRequest,
	prepareUpdateAssignedUsersRequest
} from './devices.parsers';
import { NOOP } from 'containers/App/app.constants';


const extraAsyncActions = [
	{
		type:           'fetchAssignedUsers',
		setStateDataFn: NOOP
	},
	{
		type:             'updateAssignedUsers',
		requestPrepareFn: prepareUpdateAssignedUsersRequest,
		setStateDataFn:   NOOP
	},
	{
		type:           'fetchCustomerDetails',
		setStateDataFn: NOOP
	},
	{
		type:             'updateCustomerDetails',
		requestPrepareFn: prepareUpdateCustomerDetailsRequest,
		setStateDataFn:   NOOP

	},
	{
		type:             'fetchDeviceProperties',
		setStateDataFn:   NOOP
	},
	{
		type:             'fetchMockDevices',
		setStateDataFn:   NOOP
	},
	{
		type:             'disableDevice',
		setStateDataFn:   (state, action) => {
      // mark device as disabled in order that the UI will be updated acccordingly without getting the device again from the backend
      const manufacturerId = action.payload.data.manufacturerId;
      state.byId[manufacturerId].status = 20; // 20 = disabled
      return state;
    }
	},
];

const deviceEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.DEVICES,
	extraAsyncActions,
	// parseFetchAllSuccess,
	parseCreateRequest,
	parseCreateSuccess,
	parseFetchAllRequest
});

const { reducer, actions, selectors } = deviceEntitySlice;
const {
	      fetchAll:        fetchDevices,
	      fetchAllPending: fetchDevicesPending,
	      fetchAllSuccess: fetchDevicesSuccess,
	      fetchAllFailure: fetchDevicesFailure,

	      create: createDevice,
	      createPending: createDevicePending,
	      createSuccess: createDeviceSuccess,
	      createFailure: createDeviceFailure,

	      fetchAssignedUsers,
	      fetchAssignedUsersFailure,
	      fetchAssignedUsersPending,
	      fetchAssignedUsersSuccess,

	      fetchCustomerDetails,
	      fetchCustomerDetailsFailure,
	      fetchCustomerDetailsPending,
	      fetchCustomerDetailsSuccess,

	      updateAssignedUsers,
	      updateAssignedUsersFailure,
	      updateAssignedUsersPending,
	      updateAssignedUsersSuccess,

	      updateCustomerDetails,
	      updateCustomerDetailsFailure,
	      updateCustomerDetailsPending,
	      updateCustomerDetailsSuccess,

	      fetchDeviceProperties,
	      fetchDevicePropertiesPending,
	      fetchDevicePropertiesSuccess,
		fetchDevicePropertiesFailure,
		
		  fetchMockDevices,
        
	      disableDevice,
	      disableDevicePending,
	      disableDeviceSuccess,
	      disableDeviceFailure,
      } = actions;

export const deviceEntityActions = {
	fetchDevices,
	fetchDevicesPending,
	fetchDevicesSuccess,
	fetchDevicesFailure,

	fetchDeviceProperties,
	fetchDevicePropertiesPending,
	fetchDevicePropertiesSuccess,
	fetchDevicePropertiesFailure,

	fetchMockDevices,

	createDevice,
	createDevicePending,
	createDeviceSuccess,
	createDeviceFailure,

	fetchAssignedUsers,
	fetchAssignedUsersFailure,
	fetchAssignedUsersPending,
	fetchAssignedUsersSuccess,

	fetchCustomerDetails,
	fetchCustomerDetailsFailure,
	fetchCustomerDetailsPending,
	fetchCustomerDetailsSuccess,

	updateAssignedUsers,
	updateAssignedUsersFailure,
	updateAssignedUsersPending,
	updateAssignedUsersSuccess,

	updateCustomerDetails,
	updateCustomerDetailsFailure,
	updateCustomerDetailsPending,
  updateCustomerDetailsSuccess,
  
  disableDevice,
  disableDevicePending,
  disableDeviceSuccess,
  disableDeviceFailure,
};


const {
	      selectAll:             selectDevices,
	      selectFetchAllPending: selectDevicesPending,
	      createSelectByIdOrIds: createSelectDevicesByIdOrIds,
	      selectCreatePending:   selectCreateDevicePending
      } = selectors;

const selectFetchAssignedUsersPending = state => {
	return _.get(state, 'entities.devices.fetchAssignedUsersLoading');
};
const selectUpdateAssignedUsersPending = state => _.get(state, 'entities.devices.updateAssignedUsersLoading');
const selectFetchCustomerDetailsPending = state => _.get(state, 'entities.devices.fetchCustomerDetailsLoading');
const selectUpdateCustomerDetailsPending = state => _.get(state, 'entities.devices.updateCustomerDetailsLoading');
const selectDisableDevicePending = state => _.get(state, 'entities.devices.disableDeviceLoading');


export const deviceEntitySelectors = {
	selectDevices,
	selectDevicesPending,
	createSelectDevicesByIdOrIds,
	selectCreateDevicePending,

	selectFetchAssignedUsersPending,
	selectUpdateAssignedUsersPending,
	selectFetchCustomerDetailsPending,
  selectUpdateCustomerDetailsPending,
  selectDisableDevicePending,
};

export default reducer;