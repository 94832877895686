import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import FormField from 'components/FormFactory/FormField';
import styled from 'styled-components';

const StyledInput = styled(Input)`
    &:disabled {
        font-weight: 600;
    }
`;

const TextField = (props) => {
	const { autoFocus } = props;
	const inputRef = React.createRef();

	useEffect(() => {
		if (autoFocus) {
			inputRef.current.focus();
		}
	}, []);

	return (
		<FormField {...props}>
            <StyledInput ref={inputRef} />
		</FormField>
	);
};

TextField.propTypes = {
	...FormField.propTypes,
	autoFocus: PropTypes.bool
};

export default TextField;