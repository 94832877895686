import React from 'react';
import { Typography } from 'antd'
import EntityGrid from 'containers/Grid/components/EntityGrid';

const { Text } = Typography;

const GroupDevicesStatus = (props) => {

  const columns = props.columns || [
    {
      key:       'deviceId',
      title:     'Device ID',
      dataIndex: 'deviceId',
      render: (deviceId, row) => {
        const textColor = row.swVersion === "1.0.1" ? "red" : "inherit";

        return <div style={{color: textColor}}>{deviceId}</div>
      }
    },
    {
      key:       'swVersion',
      title:     'Curr Soft Ver',
      dataIndex: 'swVersion',
      sorter:    true,
      render: (version, row) => {
        const textColor = row.swVersion === "1.0.1" ? "red" : "inherit";

        return <div style={{color: textColor}}>{version}</div>
      }
    },
    {
      key:       'swUpdateDate',
      title:     'Update Date',
      dataIndex: 'swUpdateDate',
      sorter:    true,
      render: (swUpdateDate, row) => {
        const textColor = row.swVersion === "1.0.1" ? "red" : "inherit";

        return <div style={{color: textColor}}>{swUpdateDate}</div>
      }
    },
    {
      key:       'lumxVersion',
      title:     'Curr Cloud SDK Ver',
      dataIndex: 'lumxVersion',
      sorter:    true,
      render: (version, row) => {
        const textColor = row.swVersion === "1.0.1" ? "red" : "inherit";

        return <div style={{color: textColor}}>{version}</div>
      }
    },
    {
      key:       'lumxUpdateDate',
      title:     'Update Date',
      dataIndex: 'lumxUpdateDate',
      sorter:    true,
      render: (lumXUpdateDate, row) => {
        const textColor = row.swVersion === "1.0.1" ? "red" : "inherit";

        return <div style={{color: textColor}}>{lumXUpdateDate}</div>
      }
    },
  ];

  return (
    <div>
      <GridHeader group={props.group} />

      <EntityGrid
        grid={props.grid}
        fetchData={props.fetchGroupDevices}
        fetchDataPending={props.isFetchDevicesLoading}
        data={props.groupDevices}
        columns={columns}
        resetGridData={props.resetDevicesGridData}
        setGridParams={props.setDevicesGridParams}
      />

    </div>
  );
  }
;

// component that shows group data
const GridHeader = (params) => {
  const group = params.group;
  const containerStyle = {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  }

  return(
    <div style={containerStyle}>
      <TitleNameAndValue 
          title={"Group Name"}
          value={group.name}
        />
        <TitleNameAndValue
          title={"Desired Software Version"}
          value={group.swVersion}
        />
        <TitleNameAndValue 
          title={"Desired Cloud SDK Version"}
          value={group.lumxVersion}
        />
    </div>
  )
}

// component for displaying name and value
const TitleNameAndValue = (params) => {
  return(
    <span>
      <Text strong>{params.title}:</Text>
      <Text style={{marginLeft: 10}}>{params.value}</Text>
    </span>
  )
}

export default GroupDevicesStatus;