
export const ALL_VALUE = 'ALL';
export const STORAGE_LOGIN_DATA_KEY = 'UserService/USER_DATA_KEY';
export const NOOP = () => {};
export const EMPTY_OBJECT = {};
export const EMPTY_ARRAY = [];
export const ACTION_STATUS = {
  "IDLE": "IDLE",
  "RUNNING": "RUNNING",
  "SUCCESS": "SUCCESS",
  "ERROR": "ERROR"
}