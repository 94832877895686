import { connect } from 'react-redux';
import { compose } from 'redux';

import GroupDevicesStatus from './../components/GroupDevicesStatus';
import { groupSelectors, groupsActions } from '../modules/groups.slice';
import { groupEntityActions, groupEntitySelectors } from 'containers/App/modules/entities/groups/groupEntity.slice';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import entitySchemas from 'containers/App/modules/api/schema';


const mapStateToProps = (state, ownProps) => {
	const editedGroupId = ownProps.groupId; //groupSelectors.selectEditedGroupId(state);
  const group = dbSelectors.selectEntitiesDenormalized(entitySchemas.group,  editedGroupId)(state);
  const grid = groupSelectors.selectGroupDevicesGrid(state);
  const groupDevices = groupEntitySelectors.selectGroupDevices(state);
    
	return {
		isFetchDevicesLoading: groupEntitySelectors.selectFetchGroupDevicePending(state),
    group,
    groupDevices: groupDevices ? groupDevices.data : [],
    versions: groupEntitySelectors.selectGroupPossibleVersions(state),
    grid,
	}
};

const withConnect = connect(mapStateToProps, { ...groupEntityActions, ...modalActions, ...groupsActions });

export default compose(
	withConnect
)(GroupDevicesStatus);