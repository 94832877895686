import { defineMessages } from 'react-intl';


export const scope = 'devices.page';

export default defineMessages({
	createDevice:       {
		id:             `${scope}.createDevice`,
		defaultMessage: 'Create Device',
	},
	deviceSerialNumber: {
		id:             `${scope}.deviceSerialNumber`,
		defaultMessage: 'Device Serial Number',
	},
	firstName:          {
		id:             `${scope}.firstName`,
		defaultMessage: 'First Name',
	},
	lastName:           {
		id:             `${scope}.lastName`,
		defaultMessage: 'Last Name',
	},
	email:              {
		id:             `${scope}.email`,
		defaultMessage: 'Email',
	},
	street:             {
		id:             `${scope}.street`,
		defaultMessage: 'Street',
	},
	streetNumber:       {
		id:             `${scope}.streetNumber`,
		defaultMessage: 'Street Number',
	},
	city:               {
		id:             `${scope}.city`,
		defaultMessage: 'City',
	},
	zipCode:            {
		id:             `${scope}.zipCode`,
		defaultMessage: 'Postal / Zip',
	},
	phoneNumber:        {
		id:             `${scope}.phoneNumber`,
		defaultMessage: 'Phone Number',
	},
	country:            {
		id:             `${scope}.country`,
		defaultMessage: 'Region / Country',
	},
	state:              {
		id:             `${scope}.state`,
		defaultMessage: 'State',
	},
	deviceType:         {
		id:             `${scope}.deviceType`,
		defaultMessage: 'Device Type',
	},
	comments:           {
		id:             `${scope}.comments`,
		defaultMessage: 'Comments',
	},
	status:             {
		id:             `${scope}.status`,
		defaultMessage: 'Status',
	},
	creationTime:       {
		id:             `${scope}.creationTime`,
		defaultMessage: 'Creation Time',
	},
	lastUpdatedTime:    {
		id:             `${scope}.lastUpdatedTime`,
		defaultMessage: 'Last Updated Time',
	},

	activatedByUserEmail:        {
		id:             `${scope}.activatedByUserEmail`,
		defaultMessage: 'Activated By',
	},
	iccid:                       {
		id:             `${scope}.iccid`,
		defaultMessage: 'ICCID',
	},
	imei:                        {
		id:             `${scope}.imei`,
		defaultMessage: 'IMEI',
	},
	applicationVersion:          {
		id:             `${scope}.applicationVersion`,
		defaultMessage: 'Application Version',
	},
	eventsKeysDictionaryVersion: {
		id:             `${scope}.eventsKeysDictionaryVersion`,
		defaultMessage: 'Events Key Dictionary Version',
	},
	systemStartByLocalClock:     {
		id:             `${scope}.systemStartByLocalClock`,
		defaultMessage: 'System Start (local)',
	},
	systemStartByInternetClock:  {
		id:             `${scope}.systemStartByInternetClock`,
		defaultMessage: 'System Start (internet)',
	},
	timestampsOffset:            {
		id:             `${scope}.timestampsOffset`,
		defaultMessage: 'Timestamp Offset',
	},
	customMessage:               {
		id:             `${scope}.customMessage`,
		defaultMessage: 'Custom Message',
	},
	lastMessageTimestamp:        {
		id:             `${scope}.lastMessageTimestamp`,
		defaultMessage: 'Last Message At',
	},
	xplatformVersion:            {
		id:             `${scope}.xplatformVersion`,
		defaultMessage: 'X-Platform Version',
	},
	['deviceType.type']:         {
		id:             `${scope}.deviceType.type`,
		defaultMessage: 'Device Type',
	},
});
