import { connect } from 'react-redux';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import appSaga from '../modules/app.saga';
import toastsSaga from '../modules/toasts/toasts.saga';
import { DAEMON } from 'utils/constants'
import _ from 'lodash';

import App from '../components/App';
import { appActions } from 'containers/App/modules/app.slice';
import injectReducer from 'utils/injectReducer';
import loginReducer from 'pages/LoginPage/modules/login.slice';
import loginSaga from 'pages/LoginPage/modules/login.saga';


const withAppSaga = injectSaga({
	key:  'appSaga',
	saga: appSaga,
	mode: DAEMON,
});

const withLoginReducer = injectReducer({
	key:     'login',
	reducer: loginReducer
});

const withLoginSaga = injectSaga({
	key: 'loginSaga',
	saga: loginSaga,
	mode: DAEMON,
});


const withToastSaga = injectSaga({
	key:  'toastsSaga',
	saga: toastsSaga,
	mode: DAEMON,
});

const mapStateToProps = (state) => {
	const isLoggedIn = !!_.get(state, 'userManagementReducer.accessToken');
	return {
		isLoggedIn,
	}
};

const withConnect = connect(mapStateToProps, appActions);

export default compose(
	withLoginReducer,
	withAppSaga,
	withLoginSaga,
	withToastSaga,
	withConnect
)(App);
