import { call, takeLatest } from "redux-saga/effects";
import BackendService from "services/BackendService";
import { lumenisXVersionEntityActions } from "containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.slice";
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { parseFetchAllSuccess } from "containers/App/modules/entities/lumenisXVersions/lumenisXVersions.parsers";
import {
	createEntityFailure,
	createEntitySuccess,
	errorToast,
	successToast,
	updateEntityFailure,
	updateEntitySuccess,
} from "containers/App/modules/entities/entities.utils";

function* fetchLumenisXVersions(action) {
	yield call(api, {
		apiFn: BackendService.fetchLumenisXVersions,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.lumenisXVersion],
		params: action.payload,
		actions: [
			lumenisXVersionEntityActions.fetchLumenisXVersionsPending,
			lumenisXVersionEntityActions.fetchLumenisXVersionsSuccess,
			lumenisXVersionEntityActions.fetchLumenisXVersionsFailure,
		],
	});
}

function* onUploadLumenisXVersion(action) {
	const params = action.payload;
	yield call(api, {
		apiFn: BackendService.createLumenisXVersion,
		params,
		actions: [
			lumenisXVersionEntityActions.createLumenisXVersionPending,
			lumenisXVersionEntityActions.createLumenisXVersionSuccess,
			lumenisXVersionEntityActions.createLumenisXVersionFailure,
		],
	});
}

/*
function* fetchLumenisXVersion(action) {
	const lumenisXVersionId = action.payload;
	yield call(api, {
		apiFn:                  BackendService.fetchLumenisXVersion,
		schema:                 schemas.lumenisXVersion,
		params:                 lumenisXVersionId,
		actions:                [
			lumenisXVersionEntityActions.fetchLumenisXVersionPending,
			lumenisXVersionEntityActions.fetchLumenisXVersionSuccess,
			lumenisXVersionEntityActions.fetchLumenisXVersionFailure
		]
	});
}

function* updateLumenisXVersion(action) {
	yield call(api, {
		apiFn:   BackendService.updateLumenisXVersion,
		params:  action.payload,
		actions: [
			lumenisXVersionEntityActions.updateLumenisXVersionPending,
			lumenisXVersionEntityActions.updateLumenisXVersionSuccess,
			lumenisXVersionEntityActions.updateLumenisXVersionFailure
		]
	});
}
*/

export default function* watchLumenisXVersionsSaga() {
	yield takeLatest(
		lumenisXVersionEntityActions.fetchLumenisXVersions,
		fetchLumenisXVersions
	);
	// yield takeLatest(lumenisXVersionEntityActions.fetchLumenisXVersion, fetchLumenisXVersion);

	yield takeLatest(
		lumenisXVersionEntityActions.createLumenisXVersion,
		onUploadLumenisXVersion
	);
	yield takeLatest(
		lumenisXVersionEntityActions.createLumenisXVersionSuccess,
		createEntitySuccess
	);
	// yield takeLatest(lumenisXVersionEntityActions.createLumenisXVersionFailure, createEntityFailure);

	// yield takeLatest(lumenisXVersionEntityActions.updateLumenisXVersion, updateLumenisXVersion);
	// yield takeLatest(lumenisXVersionEntityActions.updateLumenisXVersionSuccess, onUpdateLumenisXVersionSuccess);
	// yield takeLatest(lumenisXVersionEntityActions.updateLumenisXVersionFailure, updateEntityFailure);

	// yield takeLatest(lumenisXVersionEntityActions.updatePreferences, updatePreferences);
	// yield takeLatest(lumenisXVersionEntityActions.updatePreferencesSuccess, onUpdatePreferencesSuccess);
	// yield takeLatest(lumenisXVersionEntityActions.updatePreferencesFailure, onUpdatePreferencesFailure);
}
