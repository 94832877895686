import React, { useEffect } from 'react';
// import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';

import AppLayoutContainer from 'containers/App/containers/AppLayoutContainer';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import LoginPage from 'pages/LoginPage';
import PrivateRoute from './PrivateRoute';
import ResetPasswordPage from 'pages/ResetPasswordPage';

import GlobalStyle from '../../../global-styles';
import ModalRoot from 'containers/ModalRoot';
import { Spin } from 'antd';
import Box from 'components/Box';


const ROUTES = {
	LOGIN:           '/login',
	FORGOT_PASSWORD: '/forgotPassword',
	RESET_PASSWORD:  '/resetPassword',
};

function App(props) {
	const {
		      isLoggedIn,
	      } = props;

	return (
		<>
			<GlobalStyle />
			<ModalRoot />

			<Switch>
				<Route exact path={ROUTES.LOGIN} component={LoginPage.component} />
				<Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage.component} />
				<Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage.component} />
				<PrivateRoute authed={isLoggedIn} path="/" component={AppLayoutContainer} />
			</Switch>
		</>
	);
}

export default App;