import React, { useEffect } from 'react';
import { Button, Table } from 'antd';
import _ from 'lodash';

import { MODAL_TYPES } from 'containers/ModalRoot/components/constants';

import EntityGrid from 'containers/Grid/components/EntityGrid';
import DevicesGridToolbar from 'pages/Devices/components/DevicesGridToolbar';
import { userNameRenderer } from 'containers/Grid/components/ColumnRenderers';
import DeviceStatus from 'pages/Devices/components/DeviceStatus';


const DevicesGrid = (props) => {
	      const {
		            isDevicesLoading,
		            devices,
		            showModal,

		            grid,
		            setGridParams,
					resetGridData,
					rowSelection,
					showAddNew,
					treeSelectorMaxTagCount,
                    GridTitle,
					composedGroupId,
					mockData,
					fetchDevices,
					fetchMockDevices,
	            } = props;

	      const onEditDeviceClick = row => () => {
		      showModal({
			      modalType:      MODAL_TYPES.DEVICE_PROPERTIES_MODAL,
			      modalProps:     {
					title:  "Device Properties",
					footer: null,
				      width:  1000,
			      },
			      componentProps: {
				      deviceId: row.id
			      }
			  });			  
		  };
		  
		  const devicesGrid = {
			...grid,
			params: {
			  ...grid.params,
			  additionalData: {
				  composedGroupId,
			  }
			}
		  }	

	      const columns =  props.columns || [
		      {
			      key:       'deviceInfo.deviceSerialNumber',
			      title:     'Serial Number',
			      dataIndex: 'deviceSerialNumber',
			      sorter:    true
		      },
		      {
			      key:       'deviceInfo.deviceType',
			      title:     'Device Type',
			      dataIndex: 'deviceType.type',
			      sorter:    true
		      },
		      {
			      key:       'deviceInfo.status',
			      title:     'Status',
			      dataIndex: 'status',
			      sorter:    true,
			      render:    (text, record) => <DeviceStatus status={text} />
		      },
		      {
			      key:       'customerDetails.firstName',
			      title:     'Customer',
			      dataIndex: 'customerDetails',
			      render:    userNameRenderer,
			      sorter:    true,
		      },
		      {
			      key:       'customerDetails.address.country',
			      title:     'Country',
			      dataIndex: 'customerDetails.address.country',
			      sorter:    true
		      },
		      {
			      key:    'actions',
			      title:  '',
			      render: (text, row) => (
				      <Button type="link" onClick={onEditDeviceClick(row)}>Properties</Button>
			      )
		      }
	      ];

	      const toolbarComponent = (
		      <DevicesGridToolbar
			      resetGridData={resetGridData}
			      setGridParams={setGridParams}
                  grid={devicesGrid}
				  showModal={showModal}
				  showAddNew={showAddNew}
				  treeSelectorMaxTagCount={treeSelectorMaxTagCount}
		      />
	      );

	      return (
		      <EntityGrid
			      toolbarComponent={toolbarComponent}
                  grid={devicesGrid}
			      fetchData={mockData ? fetchMockDevices : fetchDevices}
			      fetchDataPending={isDevicesLoading}
			      data={devices}
			      columns={columns}
			      resetGridData={resetGridData}
				  setGridParams={setGridParams}
				  rowSelection={rowSelection}
				  GridTitle={GridTitle}
		      />
	      );
      }
;

export default DevicesGrid;