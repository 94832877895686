import React, { useEffect, useState } from "react";
import { Button } from "antd";
import _ from "lodash";
import { withErrorBoundary } from "components/ErrorBoundary";
import DevicesGridContainer from "pages/Devices/containers/DevicesGridContainer";
import styled from "styled-components";
import AssignAllRenderer from "./AssignAllRenderer";
import WarningModal from "./WarningModal";
import { deviceGridColumns } from './DeviceGridColumns';


const PaddedDiv = styled.div`
	padding-bottom: 1.5em;
`;

const UpdateAssignedDevicesButton = styled(Button).attrs({ type: "primary" })`
	float: right;
`;

const BoldText = styled.div`
	font-weight: 700;
`;

const GridTitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 1em;
`;

const AssignDevicesModal = (props) => {
	const {
		groupId,
		assignedDevices,
		setAssignedDevices,
		fetchAssignedDevices,
		canAssignDevices = true,
		updateAssignedDevices,
		currentDeviceIds,
		updateAssignedDevicesWithAllMode,
		groupName,
	} = props;

	const [isAssignedAllMode, setIsAssignedAllMode] = useState(groupName === "Group 2");
	const [excludedFromAll, setExcludedFromAll] = useState([]);
	const [prevNumberOfSelectedRows, setPrevNumberOfSelectedRows] = useState([]);
	const [warningModalProps, setWarningModalProps] = useState({
		visible: false,
		headerText: "",
		warningText: "",
		areYouSureText: "",
	});

	const selectedRowKeys = isAssignedAllMode
		? _.difference(currentDeviceIds, excludedFromAll)
		: _.map(assignedDevices, (device) => device.deviceId);

	useEffect(() => {
		fetchAssignedDevices();
	}, []);

	const onUpdateDevicesAssignment = () => {
		if (isAssignedAllMode) {
			updateAssignedDevicesWithAllMode({ excluded: excludedFromAll, groupId });
		} else {
			updateAssignedDevices({ devicesIds: selectedRowKeys, groupId });
		}
	};

	const handleAssignedAllModeSelectionChange = (selectedRowKeys) => {
		const uncheckedRows = _.difference(currentDeviceIds, selectedRowKeys);
		const withoutReselectedRows = _.difference(
			excludedFromAll,
			selectedRowKeys
		); //remove rows that were unchecked and checked again
		const excludedFromAllArr = _.union(uncheckedRows, withoutReselectedRows);

		setExcludedFromAll(excludedFromAllArr);
	};

	const handleNormalModeSelectionChange = (selectedRowKeys, selectedRows) => {
		const assignedDevices = _.map(selectedRowKeys, (rowKey) => ({
			deviceId: rowKey,
		}));

		if (prevNumberOfSelectedRows < selectedRows.length) {
			const selectedRow = selectedRows[selectedRows.length - 1];
			const isDeviceAssociatedWithGroup = selectedRows.length % 3 === 0; //TODO: when supported check if this device has associated group

			if (isDeviceAssociatedWithGroup) {
				const deviceSerialNumber = selectedRow.deviceSerialNumber;

				setWarningModalProps({
					visible: true,
					headerText: "Warning",
					warningText: `Device I-1567899 is already assigned to group USA Illumisite`,
					areYouSureText: "Are you sure you want to continue?",
					onOk: () => onApproveGroupOverride(assignedDevices),
					onCancel:  hideWarningModal,
				});
			} else {
				setAssignedDevices(assignedDevices);
				setPrevNumberOfSelectedRows(selectedRows.length);	
			}
		} else {
			setAssignedDevices(assignedDevices);
			setPrevNumberOfSelectedRows(selectedRows.length);	
		}
	};

	const onSelectionChange = (selectedRowKeys, selectedRows) => {
		if (isAssignedAllMode) {
			handleAssignedAllModeSelectionChange(selectedRowKeys);
		} else {
			handleNormalModeSelectionChange(selectedRowKeys, selectedRows);
		}
	};

	const onApproveGroupOverride = (assignedDevices) => {
		setAssignedDevices(assignedDevices);
		setPrevNumberOfSelectedRows(assignedDevices.length);
		hideWarningModal();
	};

	const hideWarningModal = () => {
		setWarningModalProps({
			visible: false,
		});
	};

	const onApproveGroupOverrideAllMode = () => {
		setIsAssignedAllMode(true);
		hideWarningModal();
	};

	const onCancelGroupOverride = () => {
		hideWarningModal();
	};

	const onAssignAllDevicesClick = () => {
		if (!isAssignedAllMode) {
			// TODO: when server will support "existsDeviceWithAssociatedGroup" boolean, add this to the if statement
			setWarningModalProps({
				visible: true,
				headerText: "Warning",
				warningText: "At least one device is already assigned to a group",
				areYouSureText: "Are you sure you want to continue?",
				onOk: onApproveGroupOverrideAllMode,
				onCancel: onCancelGroupOverride,
			});
		}
	};

	const rowSelection = {
		columnTitle: (
			<AssignAllRenderer onSelectAllClick={onAssignAllDevicesClick} />
		),
		columnWidth: 150,
		onChange: onSelectionChange,
		selectedRowKeys,
		getCheckboxProps: (record) => ({
			disabled: false, // !canAssignDevices,
			name: record.name,
		}),
		hideDefaultSelections: true
	};

	return (
		<PaddedDiv>
			<WarningModal {...warningModalProps} />
			<DevicesGridContainer
				showAddNew={false}
				columns={deviceGridColumns}
				rowSelection={rowSelection}
				treeSelectorMaxTagCount={1}
				mockData={true}
				GridTitle={<GridTitleWrapper><BoldText>Group Name: &nbsp;</BoldText> {groupName}</GridTitleWrapper>}
			/>

			{canAssignDevices && (
				<UpdateAssignedDevicesButton onClick={onUpdateDevicesAssignment}>
					Update Device Assignment
				</UpdateAssignedDevicesButton>
			)}
		</PaddedDiv>
	);
};

export default withErrorBoundary(AssignDevicesModal);
