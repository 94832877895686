import { call, put, takeLatest } from "redux-saga/effects";
import BackendService, { swVersionsData } from "services/BackendService";
import { swVersionEntityActions } from "containers/App/modules/entities/swVersions/swVersionEntity.slice";
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { parseFetchAllSuccess } from "containers/App/modules/entities/swVersions/swVersions.parsers";
import {
	createEntityFailure,
	createEntitySuccess,
	errorToast,
	successToast,
	updateEntityFailure,
	updateEntitySuccess,
} from "containers/App/modules/entities/entities.utils";

function* fetchSwVersions(action) {
	yield call(api, {
		apiFn: BackendService.fetchSwVersions,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.swVersion],
		params: action.payload,
		actions: [
			swVersionEntityActions.fetchSwVersionsPending,
			swVersionEntityActions.fetchSwVersionsSuccess,
			swVersionEntityActions.fetchSwVersionsFailure,
		],
	});
}

function* onUploadSwVersion(action) {
	const params = action.payload;
	const swVersions = swVersionsData;

	swVersions.data.data = [
		...swVersions.data.data,
		{
			id:         params.version,
			type:       deviceTypeIdToLabelMap[params.deviceTypeFilter[0]],
			version:    params.version,
			uploadDate: new Date().toLocaleDateString(),
			uploadedBy: 'Ran Lev',
			status:     0,
		}
	]

	yield call(api, {
		apiFn: BackendService.createSwVersion,
		params,
		actions: [
			swVersionEntityActions.createSwVersionPending,
			swVersionEntityActions.createSwVersionSuccess,
			swVersionEntityActions.createSwVersionFailure,
		],
	});
	yield call(fetchSwVersions, action);
}

/*
function* fetchSwVersion(action) {
	const swVersionId = action.payload;
	yield call(api, {
		apiFn:                  BackendService.fetchSwVersion,
		schema:                 schemas.swVersion,
		params:                 swVersionId,
		actions:                [
			swVersionEntityActions.fetchSwVersionPending,
			swVersionEntityActions.fetchSwVersionSuccess,
			swVersionEntityActions.fetchSwVersionFailure
		]
	});
}


function* updateSwVersion(action) {
	yield call(api, {
		apiFn:   BackendService.updateSwVersion,
		params:  action.payload,
		actions: [
			swVersionEntityActions.updateSwVersionPending,
			swVersionEntityActions.updateSwVersionSuccess,
			swVersionEntityActions.updateSwVersionFailure
		]
	});
}
*/

export default function* watchSwVersionsSaga() {
	yield takeLatest(swVersionEntityActions.fetchSwVersions, fetchSwVersions);
	// yield takeLatest(swVersionEntityActions.fetchSwVersion, fetchSwVersion);

	yield takeLatest(swVersionEntityActions.createSwVersion, onUploadSwVersion);
	yield takeLatest(
		swVersionEntityActions.createSwVersionSuccess,
		createEntitySuccess
	);
	// yield takeLatest(swVersionEntityActions.createSwVersionFailure, createEntityFailure);

	// yield takeLatest(swVersionEntityActions.updateSwVersion, updateSwVersion);
	// yield takeLatest(swVersionEntityActions.updateSwVersionSuccess, onUpdateSwVersionSuccess);
	// yield takeLatest(swVersionEntityActions.updateSwVersionFailure, updateEntityFailure);

	// yield takeLatest(swVersionEntityActions.updatePreferences, updatePreferences);
	// yield takeLatest(swVersionEntityActions.updatePreferencesSuccess, onUpdatePreferencesSuccess);
	// yield takeLatest(swVersionEntityActions.updatePreferencesFailure, onUpdatePreferencesFailure);
}


//only for medtronic demo
const deviceTypeIdToLabelMap = {
	'16e1df66-f0fe-459c-8ce4-0f1fc35017fc': 'SuperDimension V7/7.1',
	'1ee2f8ac-8fab-4450-a1f1-ed253ea04454': 'SuperDimension V7/7.2',
	'21581de3-08c4-4a16-96f7-4e7ded96123d': 'Microcap™ Handheld Capnographs',
	'243344de-41a4-4b27-b204-3059f335683c': '1.0',
	'51aae34f-3660-4844-adeb-a06689effa1a': 'Puritan Bennett™ 840 Ventilator',
	'6a3f7b15-fa03-454c-83f7-c5103aac9e97': 'Newport™ e360 Ventilator',
	'2cf54988-38f6-465d-bdcb-a3f513cc825f': 'ILS',
	'460ed28e-a889-416e-b8b7-2aa378843e57': 'SuperDimension V7',
	"c5faa87a-30f6-4fb9-8359-8811ed75ee3c": "RESPIRATORY",
	"00a4ed61-7093-4808-9d16-42971a65da67": 'Illumisite/1.0',
	"243344de-41a4-4b27-b204-3059f335683121": 'superDimension Navigation System',
	"00a4ed61-7093-4808-9d16-42971a65da6121": 'INTERVENTIONAL LUNG SOLUTIONS',
	"c5faa87a-30f6-4fb9-8359-8811ed75ee3121": 'Lung Health'
}