import { combineReducers } from 'redux';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import devicesReducer from 'containers/App/modules/entities/devices/deviceEntity.slice';
import userGroupsReducer from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import deviceTypesReducer from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import countriesReducer from 'containers/App/modules/entities/countries/countries.slice';
import alarmsReducer from 'containers/App/modules/entities/alarms/alarmsEntity.slice';
import usersReducer from 'containers/App/modules/entities/users/userEntity.slice';
import customerDetailsReducer from 'containers/App/modules/entities/customerDetails/customerDetailsEntity.slice';
import groupsReducer from 'containers/App/modules/entities/groups/groupEntity.slice';
import swVersionsReducer from 'containers/App/modules/entities/swVersions/swVersionEntity.slice';
import lumenisXVersionsReducer from 'containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.slice';


const reducer = combineReducers({
	[ENTITY_TYPES.ALARMS]:           	alarmsReducer,
	[ENTITY_TYPES.COUNTRIES]:        	countriesReducer,
	[ENTITY_TYPES.CUSTOMER_DETAILS]: 	customerDetailsReducer,
	[ENTITY_TYPES.DEVICES]:          	devicesReducer,
	[ENTITY_TYPES.DEVICE_TYPES]:     	deviceTypesReducer,
	[ENTITY_TYPES.GROUPS]:           	groupsReducer,
	[ENTITY_TYPES.SW_VERSIONS]:      	swVersionsReducer,
	[ENTITY_TYPES.LUMENISX_VERSIONS]:	lumenisXVersionsReducer,
	[ENTITY_TYPES.USERS]:            	usersReducer,
	[ENTITY_TYPES.USER_GROUPS]:      	userGroupsReducer,
});

export default reducer;