import { createSelector } from 'reselect';
import { ENTITIES_STATE_KEY, ENTITIES_INITIAL_STATE } from './entities.constants';
import _ from 'lodash';
import { EMPTY_OBJECT } from 'containers/App/app.constants';
import { DEFAULT_DATA_PROP, DEFAULT_LOADING_PROP } from 'containers/App/modules/redux.contants';
import { denormalize } from 'normalizr';


const getEntitiesState = state => state[ENTITIES_STATE_KEY] || ENTITIES_INITIAL_STATE;

//todo check if this need to be memoized
export const createEntityStateSelector = entityType =>
	createSelector(
		getEntitiesState,
		entitiesState => entitiesState[entityType]
	);

export const createEntityIsLoadingSelector = (entityType, loadingProp = DEFAULT_LOADING_PROP) =>
	createSelector(
		createEntityStateSelector(entityType),
		entityState => _.get(entityState, loadingProp)
	);

export const createEntityStateDataSelector = (entityType, dataProp = DEFAULT_DATA_PROP) =>
	createSelector(
		createEntityStateSelector(entityType),
		entityState => _.get(entityState, dataProp)
	);

export const createEntitiesSelector = (entityType) =>
	createSelector(
		createEntityStateDataSelector(entityType),
		entityStateData => {
			if (entityStateData) {
				return _.map(entityStateData.allIds, id => entityStateData.byId[id]);
			}
		}
	);

//ids can be a single object id (string) or an array with object ids (array of strings)
// if ids is a string of object id - a single user will be selected (object)
// if ids is an array of string object ids - a list of users will be returned (array)
export const createEntitiesByIdOrIdsSelector = (entityType) => (ids) =>
	createSelector(
		createEntityStateDataSelector(entityType),
		entityStateData => {
			if (!ids) return;

			if (entityStateData) {
				const { byId = EMPTY_OBJECT } = entityStateData;

				if (_.isString(ids)) {
					return byId[ids];
				} else {
					return _.map(ids, id => byId[id]);
				}
			}
		}
	);

/* DB Selectors moved here - todo change names and maybe remove other generic selectors in this file */
const getDbState = createSelector(
	getEntitiesState,
	entitiesState => {
		//normalizr's denormalize works with the whole entities flat state
		//keeping UI data here prevents me from spreading the data directly in the reducer under "users" (for example)
		//so I keep it in "users.byId" and for denormalize I need to create a flat entities state here.
		//this is why UI data needs to be in another reducer
		const compiledState = _.reduce(entitiesState, (result, value, key) => {
			result[key] = _.get(value, 'byId');
			return result;
		}, {});
		return compiledState;
	}
);

const getEntityState = entityType =>
	createSelector(
		getDbState,
		dbState => dbState[entityType] || EMPTY_OBJECT
	);

const selectEntities = (entityType, ids) =>
	createSelector(
		getEntityState(entityType),
		entityState => {
			if (ids) {
				if (_.isString(ids)) {
					return _.get(entityState, ids);
				} else {
					return _.values(_.pick(entityState, ids));
				}
			}
		}
	);

const selectAllEntities = (entityType) =>
	createSelector(
		getEntityState(entityType),
		entityState => {
			return _.values(entityState)
		}
	);


const selectEntitiesDenormalized = (schema, ids) =>
	createSelector(
		getDbState,
		(dbState) => {
			const denormData = denormalize(ids, schema, dbState);
			return denormData;
		}
	);

export const dbSelectors = {
	selectAllEntities,
	selectEntities,
	selectEntitiesDenormalized
};