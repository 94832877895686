import { call, put } from 'redux-saga/effects';
import _ from 'lodash';
import invariant from 'invariant';
import { normalize } from 'normalizr';
import { setEntities } from 'containers/App/modules/entities/entities.utils';

/**
 * default response parser. can be injected from outside
 * @param response
 * @returns {*}
 */
const defaultResponseParser = (responseData) => responseData;
const defaultMetadataParser = (responseData) => responseData.metadata;

export function* api({
	                        apiFn,
	                        params,
	                        actions,
	                        parseSuccessResponseFn = defaultResponseParser,
	                        parseMetadataFn = defaultMetadataParser,
							schema
}) {

	invariant(apiFn,
		'BackendService function (apiFn) must be provided');

	invariant(actions && _.isArray(actions) && actions.length === 3,
		'appActions must be an array with 3 action creators in order: PENDING, SUCCESS, FAILURE');

	const [pendingAction, successAction, failureAction] = actions;
	try {
		yield put(pendingAction());

		const response = yield call(apiFn, params);

		const responseData = response.data || response;
		const parsedData = parseSuccessResponseFn(responseData);
		let entities;
		if (schema) {
			entities = normalize(parsedData, schema);
			yield put(setEntities(entities));
		}
        const metadata = parseMetadataFn(responseData);

		const data = entities ? entities  : parsedData;
		yield put(successAction({data, metadata, requestParams: params}));

	} catch (err) {
		const errorCode = _.get(err, 'response.data.code', _.get(err, 'message', err));
		console.log(`API Error: `, err);
		yield put(failureAction({errorCode, params}));
	}
}
