import React from "react";
import PropTypes from "prop-types";
import FormFactory from "components/FormFactory";
import { Col, Row } from "antd";
import { withErrorBoundary } from "components/ErrorBoundary";
import TextAreaField from "components/FormFactory/fields/TextAreaField";
import TextField from "components/FormFactory/fields/TextField";
import FileBrowserField from "components/FormFactory/fields/FileBrowserField";
import commonMessages from "src/commonMessages";
import messages from "../messages";
import DeviceTypeTreeSelector from "containers/DeviceTypeTreeSelector/index";
import FormField from "components/FormFactory/FormField";

const Form = FormFactory.create({
	name: "creatSwVersionsForm",
	onFieldsChange: (props, changedFields, allFields) => {
		console.log(changedFields);
	},
});

class SwVersionsForm extends React.Component {
	static propTypes = {
		intl: PropTypes.object.isRequired,
	};

	onSubmit = (swVersionValues) => {
		this.props.createSwVersion(swVersionValues);
	};

	onCancel = (form) => {
		this.props.hideModal();
	};

	render() {
		const { isCreateUpdateLoading, intl } = this.props;
		const submitButtonText = intl.formatMessage(commonMessages.save);
		const uploadText = intl.formatMessage(messages.upload);
		const versionText = intl.formatMessage(messages.version);
		const commentsText = intl.formatMessage(messages.comments);

		return (
			<Form
				submitButtonText={submitButtonText}
				hideSubmitButton={false}
				onSubmit={this.onSubmit}
				isLoading={isCreateUpdateLoading}
				hideCancelButton={false}
				onCancel={this.onCancel}
			>
				{(form) => (
					<Row gutter={24}>
						<Col span={9}>
							<FormField name="deviceTypeFilter" label="Device Type" required style={{ minWidth: 350 }}>
								<DeviceTypeTreeSelector allowClear={true} />
							</FormField>
							<FileBrowserField
								name="upload"
								label={uploadText}
								required
								disabled={false}
							/>
							<TextField
								name="version"
								label={versionText}
								required
								disabled={false}
								maxLength={36}
							/>
							<TextAreaField
								name="comments"
								label={commentsText}
								autoSize={{ minRows: 6, maxRows: 6 }}
								maxLength={256}
								disabled={false}
							/>
						</Col>
					</Row>
				)}
			</Form>
		);
	}
}

export default withErrorBoundary(SwVersionsForm);
