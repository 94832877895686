import React, { useEffect, useState } from 'react';
import { Button, Select, Table, Icon } from 'antd';
import styled from 'styled-components';

import { MODAL_TYPES } from 'containers/ModalRoot/components/constants';

import EntityGrid from 'containers/Grid/components/EntityGrid';
import UsersGridToolbar from 'pages/Users/components/UsersGridToolbar';
import { userNameRenderer } from 'containers/Grid/components/ColumnRenderers';


const UsersTable = styled(EntityGrid)`
	.locked-col {
		padding-left: 0;
		padding-right: 0;
		color: red;
	}
`;

const UsersGrid = (props) => {
	const {
		      canUpdateUser,
		      loggedInUserGroup,
		      fetchUsers,
		      fetchUser,
		      isUsersLoading,
		      users,
		      grid,
		      showModal,
		      setGridParams,
		      resetGridData,
		      clearEditedUser,

		      columns,
		      showAddNew = true,
		      rowSelection,
		      size
	      } = props;

	const canUpdate = (row) => canUpdateUser && row.group.id !== loggedInUserGroup;

	const onEditUserClick = row => event => {
		fetchUser(row.id); //TODO: should be in form itself
		showModal({
			modalType:      MODAL_TYPES.CREATE_USER_MODAL,
			modalProps:     {
				title:  "Edit User",
				footer: null,
				width:  1100,
			},
			componentProps: {
				userId:      row.id,
				editBlocked: !canUpdate(row)
			}
		});
	};

	const cols = columns || [
		{
			key:       'lock',
			align:     'center',
			width:     20,
			render:    (text, row) => row.locked ? <Icon type="lock" /> : '',
			className: 'locked-col'
		},
		{
			key:    'firstName',
			title:  'Name',
			render: (text, row) => `${row.firstName} ${row.lastName}`,
			sorter: true,
		},
		{
			key:       'email',
			title:     'Email',
			dataIndex: 'email',
			sorter:    true
		},
		{
			key:       'phone',
			title:     'Phone',
			dataIndex: 'phone',
			sorter:    true,
		},
		{
			key:       'groupName',
			dataIndex: 'group.name',
			title:     'User Group',
			sorter:    true,
		},
		{
			key:       'managerFirstName',
			dataIndex: 'manager',
			title:     'Manager',
			render:    userNameRenderer,
			sorter:    true,
		},
		{
			key:    'actions',
			title:  '',
			render: (text, row) => {
				const btnText = canUpdate(row) ? 'Edit' : 'View';
				return (
					<Button type="link" onClick={onEditUserClick(row)}>
						{btnText}
					</Button>
				)
			}
		}
	];

	const toolbarComponent = (
		<UsersGridToolbar
			resetGridData={resetGridData}
			setGridParams={setGridParams}
			grid={grid}
			showAddNew={showAddNew}
			showModal={showModal}
			clearEditedUser={clearEditedUser}
		/>
	);
	return (
		<UsersTable
			toolbarComponent={toolbarComponent}
			grid={grid}
			fetchData={fetchUsers}
			fetchDataPending={isUsersLoading}
			data={users}
			columns={cols}
			resetGridData={resetGridData}
			setGridParams={setGridParams}

			rowSelection={rowSelection}
			size={size}
		/>
	);
};

UsersGrid.displayName = 'UsersGrid';

export default React.memo(UsersGrid);