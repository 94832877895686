import { call, takeLatest, put } from 'redux-saga/effects';
import BackendService from 'services/BackendService';
import { actions as alarmsActions, selectors as alarmSelectors } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';

/**
 * Search can be done by email, mac or udi. 
 * Since the backend doesn't have one api to search by all these params, then we search first by user email and if we don't find, we search by mac/udi (on device service), i.e. we aggregate the search on the client side
 * In case of finding the user by mac/udi, then we need to get some of the user details from the ums (e.g. email, name) - in addition to the call to tenant service
 */
function* getAlarms(action) {
  // const deviceI = action.payload.searchText;
  try {
    const alarmsResult = yield call(BackendService.fetchAlarms, action.payload);
    yield put(alarmsActions.getAlarmsSuccess(alarmsResult));
  } catch (error) {
    yield put(alarmsActions.getAlarmsFailed());
  }
}

export default function* watchUsersSaga() {
  yield takeLatest(alarmsActions.getAlarms, getAlarms);
}