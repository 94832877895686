import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { createSelector } from 'reselect';

import { actions as alarmsActions } from 'containers/App/modules/entities/alarms/alarmsEntity.slice';
import { DEFAULT_LOADING_PROP } from 'containers/App/modules/redux.contants';
import { createResetGridDataAction, createResetGridReducer, createSetGridParamsAction, createSetGridParamsReducer, createUpdateGridReducer, GRID_INITIAL_STATE } from 'containers/Grid/grid.utils';


const STATE_KEY = 'alarms';
const GRID_STATE_KEY = 'alarmsGrid';
const setGridParams = createSetGridParamsAction(GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(GRID_STATE_KEY);

export const INITIAL_STATE = {
	[GRID_STATE_KEY]: GRID_INITIAL_STATE
};

const slice = createSlice({
	name:          STATE_KEY,
	initialState:  INITIAL_STATE,
	reducers:      {
		setAssignedUsers: (state, action) => {
			state.assignedUsers = action.payload;
		},

		clearEditData: (state) => {
			delete state.assignedUsers;
			delete state.customerDetails;
		},
	},
	extraReducers: {
		//GRID
		[alarmsActions.getAlarmHistory]: createUpdateGridReducer(GRID_STATE_KEY),
		[setGridParams]:                 createSetGridParamsReducer(GRID_STATE_KEY),
		[resetGridData]:                 createResetGridReducer(GRID_STATE_KEY),
	}
});

const getState = state => state[STATE_KEY] || INITIAL_STATE;

const selectAlarmsGrid = createSelector(
	getState,
	devicesState => {
		return devicesState[GRID_STATE_KEY]
	}
);

// const selectDeviceIdsForPage = (state, props) => {
// 	const gridState = selectAlarmsGrid(state);
// 	const pageNumber = _.get(gridState, 'params.pagination.current');
// 	return gridState.pages[pageNumber];
// };

export const selectors = {
	selectAlarmsGrid,
};

const {
	      clearEditedDevice,
	      setAssignedUsers,
	      clearEditData
      } = slice.actions;
export const actions = {
	clearEditedDevice,
	setAssignedUsers,
	clearEditData,
	setGridParams,
	resetGridData
};
export default slice.reducer;