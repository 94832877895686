import { createAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { NOOP } from 'containers/App/app.constants';


export const GRID_INITIAL_STATE = {
	params:     {
		pagination: {
			current:  1,
			pageSize: 10,
		},
		sorter:     {},
		search:     '',
		filters:    {}
	},
	currentIds: undefined,
};

const DEFAULT_METADATA_KEYS = {
    page: 'page',
    sort: 'sort',
}

export const createGridInitialState = gridId => ({ gridId, ...GRID_INITIAL_STATE});

export const createResetGridReducer = gridId => (state) => {
	state[gridId] = createGridInitialState(gridId);
};

export const createSetGridParamsReducer = gridId => (state, action) => {
	// if (!(action.payload.gridId === state[gridId].gridId)) return;
	const { pagination, sorter, search, filters } = action.payload;
	// const sortForDefaultSort = !_.isEmpty(sorter) ? {
	// 	...sorter,
	// 	order: sorter.order || false
	// } : sorter;
	//todo difficult merge - undefined props sometimes should merge and sometimes should delete props and not merge (e.g. sortOrder)
	// that's why we send ...grid.params with every setGridParams()
	state[gridId].params = _.assign(state[gridId].params, { pagination, search, filters, sorter });
	// function customizer(objValue, srcValue, key, object, source, stack) {
	// const sorter = 	_.get(source, 'sorter');
	// const order = _.get(sorter, 'order');
	// 	if (sorter && !order && key === 'sorter') {
	// 		console.log(`hi`)
	// 	}
	// });
};

export const createUpdateGridReducer = (gridId, {page, sort} = DEFAULT_METADATA_KEYS) => (state, action) => {
	const { data: { result }, metadata } = action.payload;

    const { [page]: pagination } = metadata;
    const { totalResults } = pagination;
	//
    state[gridId].params.pagination.total = totalResults;
	state[gridId].currentIds = result;
	//todo think about this - metadata should be converted in prepare function
	// state.usersGrid.params.sorter.columnKey = sort[0].prop;
    // state.usersGrid.params.sorter.order = sort[0].order === 'ASC' ? 'ascend' : 'descend';
};


export const createSetGridParamsAction = gridId => createAction(`[${gridId}]/setGridParams`);
export const createResetGridDataAction = gridId => createAction(`[${gridId}]/resetGridData`);
