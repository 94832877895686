import { connect } from 'react-redux';
import { compose } from 'redux';

import UserForm from 'pages/Users/components/UserForm';
import { userEntitySelectors } from 'containers/App/modules/entities/users/userEntity.slice'

import injectReducer from 'utils/injectReducer';
import userReducer, { usersActions, userSelectors } from '../modules/users.slice';
import usersSaga from '../modules/users.saga';
import { userEntityActions } from 'containers/App/modules/entities/users/userEntity.slice';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import { modalActions } from 'containers/ModalRoot/modules/modal.slice';
import { dbSelectors } from 'containers/App/modules/entities/entities.selectors';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import entitySchemas from 'containers/App/modules/api/schema';


const withReducer = injectReducer({
	key:     'users',
	reducer: userReducer
});

const withSaga = injectSaga({
	key:  'usersSaga',
	saga: usersSaga,
	mode: DAEMON
});

const mapStateToProps = (state, ownProps) => {
	const editedUserId = ownProps.userId; //userSelectors.selectEditedUserId(state);
	const user = dbSelectors.selectEntitiesDenormalized(entitySchemas.user,  editedUserId)(state);

	const potentialManagersIds = userSelectors.selectEditedUserPotentialManagersIds(state);
	const selectPotentialManagers = dbSelectors.selectEntities(ENTITY_TYPES.USERS,  potentialManagersIds);
	const managers = selectPotentialManagers(state);


	return {
		isUserFetchLoading:     userEntitySelectors.selectFetchUserPending(state),
		isUserOperationLoading: userEntitySelectors.selectCreateUserPending(state),
		resetPasswordPending: userEntitySelectors.selectResetPasswordForUserPending(state),
		managers,
		isManagersLoading:      userEntitySelectors.selectIsPotentialManagersPending(state),
		user,
	}
};

const withConnect = connect(mapStateToProps, { ...userEntityActions, ...modalActions, ...usersActions });

export default compose(
	withReducer,
	withSaga,
	withConnect
)(UserForm);